import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from 'reactstrap';
import classnames from 'classnames';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useCurrentLang, useIntl } from 'react-redux-app/lib/modules/i18n/hooks';
import { useIsAgency, useIsAuthenticated } from '../../../auth/hooks';
import { useExternalRegisterModelUrl, useIsModelHub } from '../../../initSettings/hooks';

import { l } from 'react-redux-app/lib/modules/i18n/utils';
import { url } from 'react-redux-app/lib/modules/routing/utils';
import {
  getUrl,
  getRegisterModelUrl,
  getContactUsUrl,
  getSiteNewsUrl,
  getArticleUrl,
  getLinksUrl,
  getLatestItemsUrl,
  getSearchUrl,
  getHowItWorksUrl,
  getVideoChatRulesUrl,
  get182257Url,
  getTermsUrl,
  getPrivacyUrl,
  getRefundUrl,
  getWebcamModelWorkspaceHintsUrl,
  getEuDsaUrl,
  getContentRemovalUrl,
  getDmcaComplaintUrl,
} from '../../utils/url';

import { RCID_COOKIE } from '../../../core/api/constants';
import { LANDING_PAGE__MEDIA_AND_TV } from '../../../landingPage/constants';
import {
  CATEGORY__JOIN,
  CATEGORY__PRODUCTS,
  CATEGORY__INFORMATION,
  CATEGORY__HELP,
  CATEGORY__OTHER,
  defaultCategoryLangKeys,
  defaultCategories,
  ARTICLE_VISIBILITY__ALL,
  ARTICLE_VISIBILITY__GUEST,
} from '../../constants/footer';

import { getUserSex } from '../../../auth/selectors';
import {
  getSiteName,
  getPayeerCompanyName,
  getPayeerAddress,
  getSocialNetworkLinks,
  getUrls,
  getNetworkUrls,
  getFooterLandingPages,
  getRcid,
  getCategoriesForLang,
  getLinksForLang,
  getArticles,
  getDoesLinksExchangeExist,
} from '../../../initSettings/selectors';
import { getMenuItems } from '../../selectors/menuItems';

import { actionShowUserRegistrationDialog } from '../../../auth/actions/dialogs/userRegistrationDialog';

import { ActionLink } from 'react-redux-app/lib/modules/actionLink/components';
import { Link } from 'react-redux-app/lib/modules/link/components';
import HtmlFromLangKey from '../../../core/components/HtmlFromLangKey';
import LangLink from '../../../core/i18n/components/LangLink';
import Zendesk from '../../../auth/components/Zendesk';
import CardLogos from '../../../deposit/components/CardLogos';
import PaymentLogos from '../../../deposit/components/PaymentLogos';

import { theming } from 'react-redux-app/lib/modules/core/utils';
import { FOOTER } from '../../constants/theme';
import baseCssJson from './styles/base/index.css.json';


const themeApi = [
  'footer',
  'footer__links',
  'categoryWithLinks',
  'category',
  'footer__linkWrapper',
  'footer__link',
  'footer__socialIcons',
  'footer__socialIcon',
  'social__facebook',
  'social__instagram',
  'social__twitter',
  'social__tiktok',
  'copyright',
  'info',
  'logos',
];

const propTypes = {
  theme: theming.getThemePropTypesShape(themeApi).isRequired,
};

const Footer = ({ theme }) => {
  const dispatch = useDispatch();

  const userSex = useShortSelector(getUserSex);
  const siteName = useShortSelector(getSiteName);
  const payeerCompanyName = useShortSelector(getPayeerCompanyName);
  const payeerAddress = useShortSelector(getPayeerAddress);
  const urls = useShortSelector(getUrls);
  const socialNetworkLinks = useShortSelector(getSocialNetworkLinks);
  const landingPages = useShortSelector(getFooterLandingPages);
  const networkUrls = useShortSelector(getNetworkUrls);
  const menuItems = useShortSelector(getMenuItems);
  const rcid = useShortSelector(getRcid);
  const categories = useShortSelector(getCategoriesForLang) || [];
  const links = useShortSelector(getLinksForLang) || [];
  const articles = useShortSelector(getArticles) || [];
  const doesLinksExchangeExist = useShortSelector(getDoesLinksExchangeExist);
  const lang = useCurrentLang();
  const isAuthenticated = useIsAuthenticated();
  const externalRegisterModelUrl = useExternalRegisterModelUrl();
  const isAgency = useIsAgency();
  const isModelHub = useIsModelHub();

  const { formatMessage: fm } = useIntl();

  const getCategory = (categoryValue, isLangKey = true) => (
    <div className={theme.category}>
      {isLangKey ? l(categoryValue) : categoryValue}
    </div>
  );
  const getLink = (to, linkValue, isLangKey = true, extraParams = {}) => (
    <li className={theme.footer__linkWrapper} key={to}>
      <LangLink className={theme.footer__link} to={to} {...extraParams}>
        {isLangKey ? l(linkValue) : linkValue}
      </LangLink>
    </li>
  );
  const getCardholderSupportUrl = (to, gateway) => getLink(to, {
    id: 'msg.cardholder-support', values: { gateway },
  }, true, {
    isExternal: true, rel: 'nofollow', target: '_self',
  });
  const getSocialLink = (to, className, titleText) => (
    <li className={theme.footer__socialIcon}>
      <Link
        isExternal
        className={classnames(theme.footer__link, className)}
        to={to}
        rel="noopener noreferrer"
        title={fm({ id: titleText })}
      />
    </li>
  );

  const categoryNameToLinksMap = useMemo(() => {
    const obj = {};
    const allCategoryNames = categories.map(category => category.name).concat(defaultCategories);
    links
      .map(link => (
        allCategoryNames.includes(link.category) ? link : { ...link, category: CATEGORY__OTHER }
      ))
      .forEach(link => {
        if (!obj[link.category]) {
          obj[link.category] = [];
        }
        obj[link.category].push(link);
      });
    return obj;
  }, [lang]); // eslint-disable-line react-hooks/exhaustive-deps
  const webmasterCategoriesWithLinks = useMemo(() => categories.filter(
    category => Object.keys(categoryNameToLinksMap).includes(category.name)
  ), [lang]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCategoryLinks = categoryName => (
    categoryNameToLinksMap[categoryName]
      ? categoryNameToLinksMap[categoryName].map(link => getLink(link.url, link.value, false))
      : null
  );

  const availableArticles = useMemo(() => articles.filter(article => (
    article.lang === lang && (
      article.visibility === ARTICLE_VISIBILITY__ALL
      || (article.visibility === ARTICLE_VISIBILITY__GUEST && !isAuthenticated)
      || article.visibility === userSex
    )
  )), [lang]); // eslint-disable-line react-hooks/exhaustive-deps

  const earnMoneyUrl = !rcid
    ? urls.earnMoneyUrl
    : url.appendQueryParams(urls.earnMoneyUrl, { [RCID_COOKIE]: rcid });

  return (
    <footer className={theme.footer}>
      <Container fluid>
        <div className={theme.footer__links}>
          <div className={theme.categoryWithLinks}>
            {getCategory(defaultCategoryLangKeys[CATEGORY__HELP])}
            <ul>
              <li className={theme.footer__linkWrapper}>
                <Zendesk className={theme.footer__link} />
              </li>
              {!isAgency && getLink(getHowItWorksUrl(), 'msg.how-it-works')}
              {!isModelHub && [
                getCardholderSupportUrl(urls.centralPaySupportUrl, 'CentralPay'),
                getCardholderSupportUrl(urls.epochSupportUrl, 'Epoch'),
                getLink(getEuDsaUrl(), 'msg.eu-digital-services-act'),
                getLink(getContentRemovalUrl(), 'msg.content-removal'),
                getLink(getDmcaComplaintUrl(), 'msg.dmca-complaint'),
              ]}
              {urls.showContactUsUrl && getLink(getContactUsUrl(), 'msg.contact-us')}
              {getCategoryLinks(CATEGORY__HELP)}
              {urls.showMediaAndTvUrl && getLink(
                getUrl(null, LANDING_PAGE__MEDIA_AND_TV), 'msg.media-and-tv'
              )}
            </ul>
          </div>

          <div className={theme.categoryWithLinks}>
            {getCategory(defaultCategoryLangKeys[CATEGORY__INFORMATION])}
            <ul>
              {getLink(getVideoChatRulesUrl(), 'msg.video-chat-rules')}
              {getLink(get182257Url(), 'msg.record-keeping-requirements')}
              {getLink(getTermsUrl(), 'msg.terms-and-conditions')}
              {getLink(getPrivacyUrl(), 'msg.privacy-policy')}
              {getLink(getRefundUrl(), 'msg.refund-policy')}
              {getLink(getSiteNewsUrl(), 'msg.news')}
              {getCategoryLinks(CATEGORY__INFORMATION)}
            </ul>
          </div>

          {!isModelHub && (
            <>
              {landingPages && (
                <div className={theme.categoryWithLinks}>
                  {getCategory('msg.category.frequent-searches')}
                  <ul>
                    {landingPages.map(({ name, path }) => getLink(path, name, false))}
                  </ul>
                </div>
              )}

              {networkUrls && (
                <div className={theme.categoryWithLinks}>
                  {getCategory('msg.category.network')}
                  <ul>
                    {networkUrls.map(({ name, link }) => getLink(link, name, false, {
                      isExternal: true,
                      rel: 'noopener nofollow',
                    }))}
                  </ul>
                </div>
              )}

              <div className={theme.categoryWithLinks}>
                {getCategory(defaultCategoryLangKeys[CATEGORY__PRODUCTS])}
                <ul>
                  {getLink(getLatestItemsUrl(), 'msg.latest-items')}
                  {getLink(getSearchUrl(), 'msg.search-models')}
                  {menuItems
                    .filter(({ footerLangKey }) => footerLangKey)
                    .map(({ footerLangKey, path }) => (
                      getLink(path, { id: footerLangKey, values: { sitename: siteName } })
                    ))}
                  {getCategoryLinks(CATEGORY__PRODUCTS)}
                </ul>
              </div>
            </>
          )}

          <div className={theme.categoryWithLinks}>
            {getCategory(defaultCategoryLangKeys[CATEGORY__JOIN])}
            <ul>
              {!isAuthenticated && [
                !isModelHub && (
                  <li className={theme.footer__linkWrapper} key="register">
                    <ActionLink
                      onClick={() => { dispatch(actionShowUserRegistrationDialog()); }}
                      className={theme.footer__link}
                    >
                      {l('msg.register')}
                    </ActionLink>
                  </li>
                ),
                !isAgency && getLink(
                  externalRegisterModelUrl || getRegisterModelUrl(),
                  'msg.become-webcam-girl',
                  true,
                  { isExternal: !!externalRegisterModelUrl }
                ),
              ]}
              {getLink(earnMoneyUrl, 'msg.earn-money', true, {
                isExternal: true,
                rel: 'noopener noreferrer nofollow',
              })}
              {!isAgency && getLink(getWebcamModelWorkspaceHintsUrl(), 'msg.webcam-settings')}
              {getCategoryLinks(CATEGORY__JOIN)}
            </ul>
          </div>

          {webmasterCategoriesWithLinks.map(category => (
            <div className={theme.categoryWithLinks} key={category.name}>
              {getCategory(category.value, false)}
              <ul>
                {getCategoryLinks(category.name)}
              </ul>
            </div>
          ))}

          {(categoryNameToLinksMap[CATEGORY__OTHER]
            || availableArticles.length > 0
            || doesLinksExchangeExist
          ) && (
            <div className={theme.categoryWithLinks}>
              {getCategory(defaultCategoryLangKeys[CATEGORY__OTHER])}
              <ul>
                {getCategoryLinks(CATEGORY__OTHER)}
                {availableArticles.map(article => getLink(
                  getArticleUrl(article.id), article.title, false
                ))}
                {doesLinksExchangeExist && getLink(getLinksUrl(), 'msg.links', true, {
                  useReactRouterLink: false,
                })}
              </ul>
            </div>
          )}
        </div>

        {Object.keys(socialNetworkLinks).length > 0 && (
          <ul className={theme.footer__socialIcons}>
            {getSocialLink(
              socialNetworkLinks.facebook,
              theme.social__facebook,
              'msg.visit-our-facebook'
            )}
            {socialNetworkLinks.twitter && getSocialLink(
              socialNetworkLinks.twitter,
              theme.social__twitter,
              'msg.visit-our-twitter'
            )}
            {getSocialLink(
              socialNetworkLinks.instagram,
              theme.social__instagram,
              'msg.visit-our-instagram'
            )}
            {socialNetworkLinks.tiktok && getSocialLink(
              socialNetworkLinks.tiktok,
              theme.social__tiktok,
              'msg.visit-our-tiktok'
            )}
          </ul>
        )}

        <div className={theme.copyright}>{`© ${new Date().getFullYear()} ${siteName}`}</div>
        <div className={theme.info}>
          <div>{l('msg.payment-by', { companyName: payeerCompanyName })}</div>
          <div>{payeerAddress}</div>
        </div>
        <div className={theme.info}>
          <HtmlFromLangKey langKey="html-text.msg.development-by" />
        </div>
        <CardLogos className={theme.logos} />
        <PaymentLogos className={theme.logos} />
      </Container>
    </footer>
  );
};

Footer.propTypes = propTypes;

export default theming.theme(
  FOOTER,
  baseCssJson,
  themeApi
)(Footer);
