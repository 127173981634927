export const THEME_QUERY_PARAM = 'theme';

export const THEME__FETISH = 'fetish';
export const THEME__FUCHSIA = 'fuchsia';
export const THEME__GOLD = 'gold';
export const THEME__GRAY = 'gray';
export const THEME__INKONTRI = 'inkontri';
export const THEME__PURPLE = 'purple';
export const THEME__REDVALENTINE = 'redvalentine';

export const themes = [
  THEME__FETISH,
  THEME__FUCHSIA,
  THEME__GOLD,
  THEME__GRAY,
  THEME__INKONTRI,
  THEME__PURPLE,
  THEME__REDVALENTINE,
];
