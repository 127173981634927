import {
  currencyToSignMap,
  CURRENCY__EUR,
  CURRENCY__USD,
  CURRENCY__GBP,
  CURRENCY__RUB,
  CURRENCY__CHF,
  CURRENCY__CRD,

  CURRENCY__BTC,
  CURRENCY__ETH,
  CURRENCY__LTC,
  CURRENCY__BCH,
  CURRENCY__XRP,
  CURRENCY__USDT_TRC20,
  CURRENCY__USDC_TRC20,
  CURRENCY__USDT_ERC20,
  CURRENCY__USDC_ERC20,
  CURRENCY__EURT_ERC20,
  CURRENCY__PYUSD_ERC20,
  CURRENCY__TRX,
  CURRENCY__DOGE,
} from '../constants/currency';

import { formatNumber } from 'react-redux-app/lib/modules/i18n/utils';


/**
 * format money to string like as "2 000.00 €", "200.50 €"
 * @param {Object} money
 * @param {Number} money.amount float value.
 * @param {String} money.currency (EUR, USD, CREDITS...)
 * @return {String}
 */

const formatStraight = money => {
  const amount = formatNumber(money.amount, 2);
  return `${amount} ${currencyToSignMap[money.currency]}`;
};

const formatReverse = money => {
  const amount = formatNumber(money.amount, 2);
  return `${currencyToSignMap[money.currency]} ${amount}`;
};

const formatCrypto = money => money.amountFormatted;

const moneyFormatterMap = {
  [CURRENCY__EUR]: formatStraight,
  [CURRENCY__USD]: formatReverse,
  [CURRENCY__GBP]: formatReverse,
  [CURRENCY__RUB]: formatStraight,
  [CURRENCY__CHF]: formatStraight,
  [CURRENCY__CRD]: formatStraight,

  [CURRENCY__BTC]: formatCrypto,
  [CURRENCY__ETH]: formatCrypto,
  [CURRENCY__LTC]: formatCrypto,
  [CURRENCY__BCH]: formatCrypto,
  [CURRENCY__XRP]: formatCrypto,
  [CURRENCY__USDT_TRC20]: formatCrypto,
  [CURRENCY__USDC_TRC20]: formatCrypto,
  [CURRENCY__USDT_ERC20]: formatCrypto,
  [CURRENCY__USDC_ERC20]: formatCrypto,
  [CURRENCY__EURT_ERC20]: formatCrypto,
  [CURRENCY__PYUSD_ERC20]: formatCrypto,
  [CURRENCY__TRX]: formatCrypto,
  [CURRENCY__DOGE]: formatCrypto,
};

export default money => moneyFormatterMap[money.currency](money);
