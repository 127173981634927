export const ACTIVE_STATUS = 'ACTIVE_STATUS';
export const BORDERED_AREA = 'BORDERED_AREA';
export const BUTTON__MATERIAL_ICON = 'BUTTON__MATERIAL_ICON';
export const BUTTON__PINK = 'BUTTON__PINK';
export const BUTTON__WHITE = 'BUTTON__WHITE';
export const CONTENT_PAGINATION = 'CONTENT_PAGINATION';
export const COPYABLE_VALUE = 'COPYABLE_VALUE';
export const DRAG_AREA = 'DRAG_AREA';
export const FILTER_PANEL = 'FILTER_PANEL';
export const FORMATTED_DATE_TIME = 'FORMATTED_DATE_TIME';
export const GALLERY_CATEGORIES = 'GALLERY_CATEGORIES';
export const GALLERY_NAVIGATION = 'GALLERY_NAVIGATION';
export const GALLERY_ITEM = 'GALLERY_ITEM';
export const GALLERY_ITEM_TITLE = 'GALLERY_ITEM_TITLE';
export const GALLERY_VIDEO_PLACEHOLDER = 'GALLERY_VIDEO_PLACEHOLDER';
export const GRID_COLS_SIZE_CONTROL = 'GRID_COLS_SIZE_CONTROL';
export const HIGHLIGHTED_TEXT = 'HIGHLIGHTED_TEXT';
export const HTML_TEXT = 'HTML_TEXT';
export const ICON__ACTION = 'ICON__ACTION';
export const ICON__PHOTO = 'ICON__PHOTO';
export const ICON__SEARCH = 'ICON__SEARCH';
export const LABEL = 'LABEL';
export const LIST_GALLERY = 'LIST_GALLERY';
export const LOADABLE_ITEMS = 'LOADABLE_ITEMS';
export const MODEL_INFO = 'MODEL_INFO';
export const NO_RECORDS_PLACEHOLDER = 'NO_RECORDS_PLACEHOLDER';
export const PAGE_SPINNER = 'PAGE_SPINNER';
export const PHOTO_INFO = 'PHOTO_INFO';
export const PHOTO_PREVIEW_WITH_DRAG_AREA = 'PHOTO_PREVIEW_WITH_DRAG_AREA';
export const TAB = 'TAB';
export const TABLE_SUMMARY = 'TABLE_SUMMARY';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';
export const USER_WITH_FAVORITE_MARK_AND_INFO = 'USER_WITH_FAVORITE_MARK_AND_INFO';
export const VIDEO_BACKGROUND = 'VIDEO_BACKGROUND';
export const VIDEO_DURATION = 'VIDEO_DURATION';
export const VIDEO_PREVIEW = 'VIDEO_PREVIEW';
export const VIEW_NUMBER = 'VIEW_NUMBER';
