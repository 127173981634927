export const REGISTRATION_TYPE__MODEL = 'model';
export const REGISTRATION_TYPE__USER = 'user';

export const registrationTypes = [
  REGISTRATION_TYPE__MODEL,
  REGISTRATION_TYPE__USER,
];

export const USER_TYPE__AGENCY = 'agency';
export const USER_TYPE__COMMON = 'common';
export const USER_TYPE__MODEL = 'seller';

export const BAN_LOCATION__CHAT = 'chat';
export const BAN_LOCATION__SITE = 'site';

export const banLocations = [
  BAN_LOCATION__CHAT,
  BAN_LOCATION__SITE,
];

export const EMAIL_TYPE__CUSTOM = 'EMAIL_TYPE__CUSTOM';
export const EMAIL_TYPE__PROFILE = 'EMAIL_TYPE__PROFILE';

export const DEPOSIT_REMIND_TYPE__FIRST = 'first';
export const DEPOSIT_REMIND_TYPE__NEW = 'new';

export const FIRST_VISIT_COOKIE = 'firstVisit';
export const COOKIE_FIRST_VISIT_EXPIRES = 1000 * 60 * 60 * 24 * 365 * 10;

export const RIV_OLD_VERSION_COOKIE = 'riv-old-version';
export const COOKIE_RIV_OLD_VERSION_EXPIRES = 1000 * 60 * 60 * 24 * 365;

export const ADVENT_GUEST_COOKIE = 'adventGuest';
export const COOKIE_ADVENT_GUEST_EXPIRES = 1000 * 10 * 60;

export const ADVENT_PRIZE_RECEIVED_COOKIE = 'adventPrizeReceived';

export const DO_NOT_ASK_ABOUT_ROULETTE_PRICE_COOKIE = 'doNotAskAboutRoulettePrice';
export const COOKIE_DO_NOT_ASK_ABOUT_ROULETTE_PRICE_EXPIRES = 1000 * 60 * 60 * 24;

export const EXPIRED_QUERY_PARAM = 'expired';
export const REFERRER_QUERY_PARAM = 'referrer';
export const SHOW_DIALOGS_QUERY_PARAM = 'showDialogs';
