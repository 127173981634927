import _pick from 'lodash/pick';

import { webcamTypeLangKeys } from '../../core/constants/model';
import {
  SALE_TYPE__EMAIL,
  SALE_TYPE__FACEBOOK,
  SALE_TYPE__INSTAGRAM,
  SALE_TYPE__MOBILE,
  SALE_TYPE__OTHER,
  SALE_TYPE__PRIVATE_PHOTO,
  SALE_TYPE__PRIVATE_VIDEO,
  SALE_TYPE__SKYPE,
  SALE_TYPE__UNDERWEAR,
  saleTypeLangKeys,
} from '../../balance/constants';


export const RIV_CHAT_STATUS__OFFLINE = 0;
export const RIV_CHAT_STATUS__PUBLIC = 1;
export const RIV_CHAT_STATUS__PERSONAL = 2;
export const RIV_CHAT_STATUS__PRIVATE_SPYCAM = 3;
export const RIV_CHAT_STATUS__PRIVATE = 4;

export const RIV_CHAT_STREAM__OFF = 0;
export const RIV_CHAT_STREAM__ON = 1;

export const FILTER_QUERY_PARAM = 'filter';
export const PRICE_RANGE_QUERY_PARAM = 'webcamPriceRange';
export const AGE_RANGE_QUERY_PARAM = 'ageRange';
export const SEX_PREFERENCES_QUERY_PARAM = 'sexPreferences';
export const ETHNIC_QUERY_PARAM = 'ethnics';
export const HAIR_QUERY_PARAM = 'hairs';
export const HEIGHT_QUERY_PARAM = 'heights';
export const BODY_QUERY_PARAM = 'bodies';
export const BREAST_QUERY_PARAM = 'breasts';
export const PUBES_QUERY_PARAM = 'pubes';
export const LANGUAGE_QUERY_PARAM = 'languages';

export const chatGalleryQueryParams = [
  PRICE_RANGE_QUERY_PARAM,
  AGE_RANGE_QUERY_PARAM,
  SEX_PREFERENCES_QUERY_PARAM,
  ETHNIC_QUERY_PARAM,
  HAIR_QUERY_PARAM,
  HEIGHT_QUERY_PARAM,
  BODY_QUERY_PARAM,
  BREAST_QUERY_PARAM,
  PUBES_QUERY_PARAM,
  LANGUAGE_QUERY_PARAM,
];

export const TS_QUERY_PARAM = 'ts';

export const FILTER__NEW = 'new';
export const FILTER__MOST_POPULAR = 'most-popular';
export const FILTER__HOT_FLIRT = 'hot-flirt';
export const FILTER__BIG_BREAST = 'big-breast';
export const FILTER__FEET = 'feet';
export const FILTER__LESBIANS = 'lesbians';
export const FILTER__YOUNG = 'young';
export const FILTER__COUPLE = 'couple';
export const FILTER__TRANSSEXUAL = 'transsexual';
export const FILTER__CHUBBY = 'chubby';
export const FILTER__OLD = 'old';
export const FILTER__BIG_COCK = 'big-cock';
export const FILTER__SMALL_COCK = 'small-cock';
export const FILTER__MUSCLE = 'muscle';
export const FILTER__CURRENT_AWARDS = 'current-awards';

export const FILTER__BELOWFOURTY = 'belowfourty';
export const FILTER__MALEMODEL = 'malemodel';
export const FILTER__MATURE = 'mature';
export const FILTER__MILF = 'milf';

export const AGE__18_22 = 'age18_22';
export const AGE__23_27 = 'age23_27';
export const AGE__28_35 = 'age28_35';
export const AGE__36_45 = 'age36_45';
export const AGE__46_120 = 'age46_120';

export const ages = [
  AGE__18_22,
  AGE__23_27,
  AGE__28_35,
  AGE__36_45,
  AGE__46_120,
];

export const PRICE__TO_20 = 'price_to_20';
export const PRICE__20_25 = 'price_20_25';
export const PRICE__25_30 = 'price_25_30';
export const PRICE__30_35 = 'price_30_35';
export const PRICE__35_40 = 'price_35_40';
export const PRICE__40_45 = 'price_40_45';
export const PRICE__45_50 = 'price_45_50';
export const PRICE__FROM_50 = 'price_from_50';

export const prices = [
  PRICE__TO_20,
  PRICE__20_25,
  PRICE__25_30,
  PRICE__30_35,
  PRICE__35_40,
  PRICE__40_45,
  PRICE__45_50,
  PRICE__FROM_50,
];

const modelSearchSaleTypes = [
  SALE_TYPE__EMAIL,
  SALE_TYPE__MOBILE,
  SALE_TYPE__SKYPE,
  SALE_TYPE__FACEBOOK,
  SALE_TYPE__INSTAGRAM,
  SALE_TYPE__OTHER,
  SALE_TYPE__UNDERWEAR,
  SALE_TYPE__PRIVATE_PHOTO,
  SALE_TYPE__PRIVATE_VIDEO,
];

export const modelSearchSaleTypeLangKeys = {
  ...webcamTypeLangKeys,
  ..._pick(saleTypeLangKeys, modelSearchSaleTypes),
};

export const CHAT_GALLERY_ID = 'chat-gallery';

export const WEEKEND_ID = 0;
export const WELCOME_ID = -1;
export const CRYPTO_ID = -2;

export const bonusLangKeys = {
  [WEEKEND_ID.toString()]: 'msg.bonus.weekend',
  [CRYPTO_ID.toString()]: 'msg.bonus.crypto',
};

export const CRYPTO_BONUS_COOKIE = 'cryptoBonus';
export const COOKIE_CRYPTO_BONUS_EXPIRES = 1000 * 60 * 60 * 24;

export const WELCOME_BONUS_ACTIVATED_COOKIE = 'welcomeBonusActivated';
export const COOKIE_WELCOME_BONUS_ACTIVATED_EXPIRES = 1000 * 60 * 60 * 24 * 365 * 10;

export const CRYPTO_BONUS_QUERY_PARAM = 'crypto';
