import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useQueryParam, useUri } from 'react-redux-app/lib/modules/routing/hooks';

import { url } from 'react-redux-app/lib/modules/routing/utils';

import { SHOW_DIALOGS_QUERY_PARAM } from '../constants';

import { actionShowDialogs } from '../actions/showDialogs';


export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUri = useUri();
  const showDialogs = !!useQueryParam(SHOW_DIALOGS_QUERY_PARAM);

  useEffect(() => {
    if (!showDialogs) {
      return;
    }

    const newUri = url.removeQueryParams(currentUri, [SHOW_DIALOGS_QUERY_PARAM]);
    navigate(newUri, { replace: true });

    dispatch(actionShowDialogs());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
