import { createSelector } from 'reselect';
import _findKey from 'lodash/findKey';
import _get from 'lodash/get';
import _intersection from 'lodash/intersection';
import _pickBy from 'lodash/pickBy';

import { methods, REWARD_REQUEST_STATUS__PENDING } from '../constants';


export const getDeposits = state => _get(state, 'deposit.byId');
export const getDeposit = (state, id) => _get(getDeposits(state), id);
export const getHasDepositDiscountCode = (state, id) => (
  !!_get(getDeposit(state, id), 'discountCode')
);
export const getIsActionable = (state, id) => (
  !!_get(getDeposit(state, id), 'isBankTransferConfirmationAvailable')
  || !!_get(getDeposit(state, id), 'isComplainAvailable')
);

const cryptowalletAddressSelector = state => _get(state, 'deposit.cryptoAddress');
export const getCryptowalletAddress = state => (
  _get(cryptowalletAddressSelector(state), 'address')
);
export const getCryptowalletDestinationTag = state => (
  _get(cryptowalletAddressSelector(state), 'destinationTag')
);

export const getIsAnyCardBeingDeleted = state => (
  Object.keys(_get(state, 'deposit.isCreditCardBeingDeleted')).length > 0
);

export const getDepositDiscountCode = state => _get(state, 'deposit.discountCode');

const depositSettingsSelector = state => _get(state, 'deposit.settings');
export const getRevenueToCreditsMinAmount = state => (
  _get(depositSettingsSelector(state), 'revenueToCreditsMinAmount')
);
export const getMethodCreditPacks = (state, method) => (
  _get(depositSettingsSelector(state), `methodToCreditPacks.${method}`)
);
export const getMethodsWithBlackbox = state => (
  _get(depositSettingsSelector(state), 'methodsWithBlackbox')
);
export const getBlackboxScriptUrl = state => (
  _get(depositSettingsSelector(state), 'blackboxScriptUrl')
);
export const getLastDeposit = state => _get(depositSettingsSelector(state), 'lastDeposit');
export const getMethods = createSelector(
  state => _get(depositSettingsSelector(state), 'methods'),
  ms => _intersection(ms, methods)
);
export const getLogo = state => _get(depositSettingsSelector(state), 'logo');

const creditsSummarySelector = state => _get(depositSettingsSelector(state), 'creditsDetails');
export const getCreditsSummary = state => _get(creditsSummarySelector(state), 'items');
export const getRequiredItems = state => _get(creditsSummarySelector(state), 'requiredItems');

const cryptoProcessorSelector = state => (
  _get(depositSettingsSelector(state), 'cryptoProcessor')
);
export const getCryptoProcessor = (state, currency) => (
  _get(cryptoProcessorSelector(state), currency)
);
export const getCryptoMethods = createSelector(
  cryptoProcessorSelector,
  cryptoProcessor => Object.keys(cryptoProcessor)
);

const paymentServiceSelector = state => _get(depositSettingsSelector(state), 'paymentService');
export const getIsEmailRequired = state => _get(paymentServiceSelector(state), 'isEmailRequired');
export const getExpirationYearRange = state => (
  _get(paymentServiceSelector(state), 'expirationYearRange')
);
export const getExistingCards = state => _get(paymentServiceSelector(state), 'existingCards');
export const getExtendedCards = createSelector(
  getExistingCards,
  existingCards => existingCards.filter(({ isExtended }) => isExtended)
);
export const getRecurringCards = createSelector(
  getExistingCards,
  existingCards => existingCards.filter(({ isRecurring }) => isRecurring)
);
export const getAutoRefillSettings = state => _get(paymentServiceSelector(state), 'autoRefill');

export const getIsWhiteModeActivated = state => _get(state, 'deposit.isWhiteModeActivated');

const gateResultSelector = state => _get(state, 'deposit.gateResult');
export const getGatewayAnswer = state => _get(gateResultSelector(state), 'answer');
export const getGatewayMessage = state => _get(gateResultSelector(state), 'gatewayMessage');
export const getGatewayAmount = state => _get(gateResultSelector(state), 'amount');
export const getGatewayDepositId = state => _get(gateResultSelector(state), 'depositId');

export const getIsBankWireWithoutDetailsConfirmationConfirmed = state => (
  _get(state, 'deposit.isBankWireWithoutDetailsConfirmationConfirmed')
);

export const getIsRevenueConversionRequestConfirmed = state => (
  _get(state, 'deposit.isRevenueConversionRequestConfirmed')
);

export const getRewardRequests = state => _get(state, 'deposit.rewardRequestsById');
export const getIsRewardRequestActionable = (state, id) => (
  _get(getRewardRequests(state), `${id}.status`) === REWARD_REQUEST_STATUS__PENDING
);

export const getIsRewardRequestBeingCanceled = (state, id) => (
  !!_get(state, `deposit.isRewardRequestBeingCanceled.${id}`)
);

const rewardSettingsSelector = state => _get(state, 'deposit.rewardSettings');
export const getRewardPoints = state => _get(rewardSettingsSelector(state), 'points');
export const getRewards = state => _get(rewardSettingsSelector(state), 'rewards');

export const getMinPoints = createSelector(
  getRewards,
  rewards => rewards
    .map(({ points }) => points)
    .reduce(
      (previousValue, currentValue) => Math.min(previousValue, currentValue),
      Number.MAX_VALUE
    )
);
