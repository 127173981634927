export const DISCOUNT_CODE_COOKIE = 'discountCode';
export const COOKIE_DISCOUNT_CODE_EXPIRES = 1000 * 60 * 60 * 24;

export const METHOD__CRYPTOPROCESSOR = 'cryptoprocessor';
export const METHOD__PAYMENTSERVICE = 'paymentservice';
export const METHOD__PAYMENTSERVICE_RYVYL = 'paymentservice-ryvyl';
export const METHOD__CENTRALPAY = 'centralpay';
export const METHOD__EPOCH = 'epoch';
export const METHOD__PAYSAFECARD = 'paysafecard';
export const METHOD__NEOSURF = 'neosurf';
export const METHOD__BANKTRANSFER = 'banktransfer';
export const METHOD__YOWPAY = 'yowpay';
// 'msg.buy-credits.convertrevenue'

export const methods = [
  METHOD__CRYPTOPROCESSOR,
  METHOD__PAYMENTSERVICE,
  METHOD__PAYMENTSERVICE_RYVYL,
  METHOD__CENTRALPAY,
  METHOD__EPOCH,
  METHOD__PAYSAFECARD,
  METHOD__NEOSURF,
  METHOD__BANKTRANSFER,
  METHOD__YOWPAY,
];

export const paymentServiceMethods = [
  METHOD__PAYMENTSERVICE,
  METHOD__PAYMENTSERVICE_RYVYL,
];

export const externalMethods = [
  METHOD__CENTRALPAY,
  METHOD__EPOCH,
  METHOD__PAYSAFECARD,
  METHOD__NEOSURF,
  METHOD__YOWPAY,
];

export const methodLangKeys = {
  [METHOD__CRYPTOPROCESSOR]: 'msg.buy-credits.cryptoprocessor',
  [METHOD__PAYMENTSERVICE]: 'msg.buy-credits.paymentservice',
  [METHOD__PAYMENTSERVICE_RYVYL]: 'msg.buy-credits.ryvyl',
  [METHOD__CENTRALPAY]: 'msg.buy-credits.centralpay',
  [METHOD__EPOCH]: 'msg.buy-credits.paypal',
  [METHOD__PAYSAFECARD]: 'msg.buy-credits.paysafecard',
  [METHOD__NEOSURF]: 'msg.buy-credits.neosurf',
  [METHOD__BANKTRANSFER]: 'msg.buy-credits.transfer',
  [METHOD__YOWPAY]: 'msg.buy-credits.yowpay',
};

export const CRYPTO_METHOD__BITCOIN = 'BTC';
export const CRYPTO_METHOD__ETHER = 'ETH';
export const CRYPTO_METHOD__LITECOIN = 'LTC';
export const CRYPTO_METHOD__BITCOIN_CASH = 'BCH';
export const CRYPTO_METHOD__RIPPLE = 'XRP';
export const CRYPTO_METHOD__USDT_TRC20 = 'USDT_TRC20';
export const CRYPTO_METHOD__USDC_TRC20 = 'USDC_TRC20';
export const CRYPTO_METHOD__USDT_ERC20 = 'USDT_ERC20';
export const CRYPTO_METHOD__USDC_ERC20 = 'USDC_ERC20';
export const CRYPTO_METHOD__EURT_ERC20 = 'EURT_ERC20';
export const CRYPTO_METHOD__PYUSD_ERC20 = 'PYUSD_ERC20';
export const CRYPTO_METHOD__TRON = 'TRX';
export const CRYPTO_METHOD__DOGECOIN = 'DOGE';

export const cryptoMethods = [
  CRYPTO_METHOD__BITCOIN,
  CRYPTO_METHOD__ETHER,
  CRYPTO_METHOD__LITECOIN,
  CRYPTO_METHOD__BITCOIN_CASH,
  CRYPTO_METHOD__RIPPLE,
  CRYPTO_METHOD__USDT_TRC20,
  CRYPTO_METHOD__USDC_TRC20,
  CRYPTO_METHOD__USDT_ERC20,
  CRYPTO_METHOD__USDC_ERC20,
  CRYPTO_METHOD__EURT_ERC20,
  CRYPTO_METHOD__PYUSD_ERC20,
  CRYPTO_METHOD__TRON,
  CRYPTO_METHOD__DOGECOIN,
];

export const GATE_ANSWER__YES = 'yes';
export const GATE_ANSWER__NO = 'no';
export const GATE_ANSWER__PENDING = 'pending';
export const GATE_ANSWER__ERROR = 'error';

export const gatewayAnswers = [
  GATE_ANSWER__YES,
  GATE_ANSWER__NO,
  GATE_ANSWER__PENDING,
  GATE_ANSWER__ERROR,
];

export const NOTIFICATION_TYPE__SUCCESS = 'success';
export const NOTIFICATION_TYPE__WARNING = 'warning';
export const NOTIFICATION_TYPE__DANGER = 'danger';

export const notificationTypes = [
  NOTIFICATION_TYPE__SUCCESS,
  NOTIFICATION_TYPE__WARNING,
  NOTIFICATION_TYPE__DANGER,
];

export const DEPOSIT_STATUS__PENDING = 'pending';
export const DEPOSIT_STATUS__APPROVED = 'approved';
export const DEPOSIT_STATUS__DECLINED = 'declined';
export const DEPOSIT_STATUS__CANCELED = 'canceled';
export const DEPOSIT_STATUS__AWAITINGCOMMIT = 'awaitingcommit';
export const DEPOSIT_STATUS__CHARGEBACK = 'chargeback';
export const DEPOSIT_STATUS__ERROR = 'error';

export const depositStatuses = [
  DEPOSIT_STATUS__PENDING,
  DEPOSIT_STATUS__APPROVED,
  DEPOSIT_STATUS__DECLINED,
  DEPOSIT_STATUS__CANCELED,
  DEPOSIT_STATUS__AWAITINGCOMMIT,
  DEPOSIT_STATUS__CHARGEBACK,
  DEPOSIT_STATUS__ERROR,
];

export const depositStatusLangKeys = {
  [DEPOSIT_STATUS__PENDING]: 'msg.status.pending',
  [DEPOSIT_STATUS__APPROVED]: 'msg.status.approved',
  [DEPOSIT_STATUS__DECLINED]: 'msg.status.declined',
  [DEPOSIT_STATUS__CANCELED]: 'msg.status.canceled',
  [DEPOSIT_STATUS__AWAITINGCOMMIT]: 'msg.status.awaiting-commit',
  [DEPOSIT_STATUS__CHARGEBACK]: 'msg.status.chargeback',
  [DEPOSIT_STATUS__ERROR]: 'msg.status.error',
};

export const cryptoMethodNames = {
  [CRYPTO_METHOD__BITCOIN]: 'Bitcoin',
  [CRYPTO_METHOD__ETHER]: 'Ether',
  [CRYPTO_METHOD__LITECOIN]: 'Litecoin',
  [CRYPTO_METHOD__BITCOIN_CASH]: 'Bitcoin Cash',
  [CRYPTO_METHOD__RIPPLE]: 'Ripple',
  [CRYPTO_METHOD__USDT_TRC20]: 'USDT TRC20',
  [CRYPTO_METHOD__USDC_TRC20]: 'USDC TRC20',
  [CRYPTO_METHOD__USDT_ERC20]: 'USDT ERC20',
  [CRYPTO_METHOD__USDC_ERC20]: 'USDC ERC20',
  [CRYPTO_METHOD__EURT_ERC20]: 'EURT ERC20',
  [CRYPTO_METHOD__PYUSD_ERC20]: 'PYUSD ERC20',
  [CRYPTO_METHOD__TRON]: 'Tron',
  [CRYPTO_METHOD__DOGECOIN]: 'Dogecoin',
};

export const methodTipLangKeys = {
  [METHOD__CENTRALPAY]: 'html-text.msg.buy-credits.centralpay',
  [METHOD__EPOCH]: 'html-text.msg.buy-credits.epoch',
  [METHOD__PAYSAFECARD]: 'html-text.msg.buy-credits.paysafecard',
  [METHOD__NEOSURF]: 'html-text.msg.buy-credits.neosurf',
  [METHOD__BANKTRANSFER]: 'html-text.msg.buy-credits.banktransfer',
  [METHOD__YOWPAY]: 'html-text.msg.buy-credits.yowpay',
};

export const methodTipLinks = {
  [METHOD__CENTRALPAY]: 'https://paysecure.eu/home/b141b374-f698-4feb-92da-6dad9395ee39',
  [METHOD__EPOCH]: 'https://epoch.com',
  [METHOD__PAYSAFECARD]: 'https://www.paysafecard.com',
};

export const REWARD_REQUEST_STATUS__PENDING = 'pending';
export const REWARD_REQUEST_STATUS__APPROVED = 'approved';
export const REWARD_REQUEST_STATUS__CANCELED = 'canceled';

export const rewardRequestStatuses = [
  REWARD_REQUEST_STATUS__PENDING,
  REWARD_REQUEST_STATUS__APPROVED,
  REWARD_REQUEST_STATUS__CANCELED,
];

export const rewardRequestStatusLangKeys = {
  [REWARD_REQUEST_STATUS__PENDING]: 'msg.status.pending',
  [REWARD_REQUEST_STATUS__APPROVED]: 'msg.status.approved',
  [REWARD_REQUEST_STATUS__CANCELED]: 'msg.status.canceled',
};

export const DEPOSIT_RESTRICTIONS__TIMES_EXCEEDED = 'DEPOSIT_RESTRICTIONS_TIMES_EXCEEDED';
export const DEPOSIT_RESTRICTIONS__SUM_EXCEEDED = 'DEPOSIT_RESTRICTIONS_SUM_EXCEEDED';
export const DEPOSIT_RESTRICTIONS__NOT_TRUSTED = 'DEPOSIT_RESTRICTIONS_NOT_TRUSTED';

export const depositRestrictionsLangKeys = {
  [DEPOSIT_RESTRICTIONS__TIMES_EXCEEDED]: 'html-text.msg.deposit-restrictions.times-exceeded',
  [DEPOSIT_RESTRICTIONS__SUM_EXCEEDED]: 'html-text.msg.deposit-restrictions.sum-exceeded',
  [DEPOSIT_RESTRICTIONS__NOT_TRUSTED]: 'html-text.msg.deposit-restrictions.not-trusted',
};

export const ANSWER_QUERY_PARAM = 'answer';
export const DEPOSIT_ID_QUERY_PARAM = 'depositId';
export const AMOUNT_QUERY_PARAM = 'amount';
export const STATE_QUERY_PARAM = 'state';
export const GATEWAY_MESSAGE_QUERY_PARAM = 'gatewayMessage';
export const WARNING_MESSAGE_QUERY_PARAM = 'warningMessage';
export const ERROR_MESSAGE_QUERY_PARAM = 'errorMessage';
export const NOTIFICATION_MESSAGE_QUERY_PARAM = 'notificationMessage';
export const NOTIFICATION_TYPE_QUERY_PARAM = 'notificationType';
