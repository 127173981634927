import loadable from '@loadable/component';

import { MODAL_DIALOG__ERROR } from 'react-redux-app/lib/modules/globalErrorDialog/constants/dialogs';

import {
  MODAL_DIALOG__ADULT_WARNING,
} from './constants/dialogs';
import {
  MODAL_DIALOG__ADD_GEO_FILTER,
  MODAL_DIALOG__ADD_WHITELISTED_USER,
  MODAL_DIALOG__UPDATE_EMAIL,
} from '../account/constants/dialogs';
import {
  MODAL_DIALOG__CREATE_MODEL,
  MODAL_DIALOG__INSPECT_MODEL,
  MODAL_DIALOG__UPDATE_PERSONAL_INFO,
} from '../agency/constants/dialogs';
import {
  MODAL_DIALOG__APPOINTMENT,
  MODAL_DIALOG__APPROVE_APPOINTMENT,
  MODAL_DIALOG__CANCEL_APPOINTMENT,
  MODAL_DIALOG__CREATE_APPOINTMENT,
} from '../appointment/constants/dialogs';
import {
  MODAL_DIALOG__ADVENT_PRIZES,
  MODAL_DIALOG__GIFT_EVENT,
  MODAL_DIALOG__FILL_NAME,
  MODAL_DIALOG__LOGIN,
  MODAL_DIALOG__MISSED_BONUSES,
  MODAL_DIALOG__PAID_ROULETTE,
  MODAL_DIALOG__PRIZES,
  MODAL_DIALOG__REGISTRATION,
  MODAL_DIALOG__ROULETTE,
  MODAL_DIALOG__SOCIAL_LOGIN_ERROR,
  MODAL_DIALOG__TERMS,
  MODAL_DIALOG__USER_REGISTRATION,
} from '../auth/constants/dialogs';
import { MODAL_DIALOG__ADD_BLOCKED_USER } from '../blackList/constants/dialogs';
import {
  MODAL_DIALOG__BUY_POST,
  MODAL_DIALOG__SUBSCRIBE,
} from '../blog/constants/dialogs';
import {
  MODAL_DIALOG__CRYPTO_BONUS,
  MODAL_DIALOG__WEEKEND_BONUS,
} from '../chatGallery/constants/dialogs';
import {
  MODAL_DIALOG__IMAGE,
  MODAL_DIALOG__NOTIFICATION,
  MODAL_DIALOG__PRESENTATION,
} from '../core/dialog/constants';
import {
  MODAL_DIALOG__CONFIRM_BANK_WIRE,
  MODAL_DIALOG__GET_CREDITS,
  MODAL_DIALOG__INVOICE,
  MODAL_DIALOG__PAYMENT_RESULT_NOTIFICATION,
  MODAL_DIALOG__REPORT_DEPOSIT_ISSUE,
  MODAL_DIALOG__UPDATE_AUTO_REFILL_SETTINGS,
} from '../deposit/constants/dialogs';
import { MODAL_DIALOG__CREATE_DIALOG } from '../dialog/constants/dialogs';
import { MODAL_DIALOG__BUY_EMOTICON } from '../emoticonShop/constants/dialogs';
import {
  MODAL_DIALOG__GIFT,
  MODAL_DIALOG__REAL_GIFT,
  MODAL_DIALOG__SEND_GIFT,
} from '../gift/constants/dialogs';
import {
  MODAL_DIALOG__CREATE_INTERVIEW_FORM,
  MODAL_DIALOG__INTERVIEW_FORM,
} from '../interview/constants/dialogs';
import { MODAL_DIALOG__REPORT_CONTENT } from '../legal/constants/dialogs';
import {
  MODAL_DIALOG__MODEL,
  MODAL_DIALOG__PERSONAL_CONTACT,
} from '../model/constants/dialogs';
import {
  MODAL_DIALOG__CREATE_POST,
  MODAL_DIALOG__SELECT_MEDIA,
  MODAL_DIALOG__UPDATE_POST,
} from '../ownPost/constants/dialogs';
import {
  MODAL_DIALOG__CREATE_COVER_PHOTO,
  MODAL_DIALOG__CREATE_EROSTORY,
  MODAL_DIALOG__CREATE_PRESENTATION,
  MODAL_DIALOG__CREATE_PRIVATE_PHOTO,
  MODAL_DIALOG__CREATE_PROFILE_PHOTO,
  MODAL_DIALOG__CREATE_PUBLIC_PHOTO,
  MODAL_DIALOG__CREATE_VIDEO,
  MODAL_DIALOG__CREATE_UNDERWEAR,
  MODAL_DIALOG__HELP,
  MODAL_DIALOG__POST_RECORDED_PRIVATE,
  MODAL_DIALOG__UPDATE_EROSTORY,
  MODAL_DIALOG__UPDATE_PERSONAL_CONTACT,
  MODAL_DIALOG__UPDATE_PRESENTATION,
  MODAL_DIALOG__UPDATE_PRIVATE_PHOTO,
  MODAL_DIALOG__UPDATE_PROFILE_PHOTO,
  MODAL_DIALOG__UPDATE_PUBLIC_PHOTO,
  MODAL_DIALOG__UPDATE_UNDERWEAR,
  MODAL_DIALOG__UPDATE_VIDEO,
} from '../ownSaleItem/constants/dialogs';
import {
  MODAL_DIALOG__CREATE_PARTNER,
  MODAL_DIALOG__UPDATE_PARTNER,
} from '../partner/constants/dialogs';
import {
  MODAL_DIALOG__AGREEMENT,
  MODAL_DIALOG__PROFILE_PHOTO,
} from '../registration/constants/dialogs';
import {
  MODAL_DIALOG__DECLINE_DETAILS,
  MODAL_DIALOG__ORDER_XCARD,
  MODAL_DIALOG__REQUEST_PAYOUT,
  MODAL_DIALOG__SEND_PAYOUT_COMPLAINT,
  MODAL_DIALOG__SUBMIT_FISCAL_NUMBER,
  MODAL_DIALOG__UPDATE_BANK_ACCOUNT_DETAILS,
  MODAL_DIALOG__UPDATE_REVOLUT_PHONE_NUMBER,
} from '../revenue/constants/dialogs';
import {
  MODAL_DIALOG__BOUGHT_UNDERWEAR,
  MODAL_DIALOG__BUY_SALE_ITEM,
  MODAL_DIALOG__BUY_SIMPLE_SALE_ITEM,
  MODAL_DIALOG__BUY_UNDERWEAR,
  MODAL_DIALOG__VIDEO,
} from '../saleItem/constants/dialogs';

import {
  CONFIRMATION_DIALOG__DELETE_GEO_FILTER,
  CONFIRMATION_DIALOG__DELETE_WHITELISTED_USER,
  CONFIRMATION_DIALOG__DISCONNECT_FROM_TELEGRAM,
  CONFIRMATION_DIALOG__GENERATE_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS,
} from '../account/constants/confirmationDialogs';
import { CONFIRMATION_DIALOG__UPDATE_MODEL_STATUS } from '../agency/constants/confirmationDialogs';
import { CONFIRMATION_DIALOG__CREATE_APPOINTMENT } from '../appointment/constants/confirmationDialogs';
import {
  CONFIRMATION_DIALOG__LOGOUT,
  CONFIRMATION_DIALOG__SPIN_ROULETTE,
} from '../auth/constants/confirmationDialogs';
import {
  CONFIRMATION_DIALOG__CANCEL_REVENUE_CONVERSION_REQUEST,
} from '../balance/constants/confirmationDialogs';
import { CONFIRMATION_DIALOG__DELETE_BANNED_USER } from '../bannedUser/constants/confirmationDialogs';
import {
  CONFIRMATION_DIALOG__ADD_BLOCKED_USER,
  CONFIRMATION_DIALOG__DELETE_BLOCKED_USER,
} from '../blackList/constants/confirmationDialogs';
import { CONFIRMATION_DIALOG__UNSUBSCRIBE } from '../blog/constants/confirmationDialogs';
import {
  CONFIRMATION_DIALOG__CANCEL_REWARD_REQUEST,
  CONFIRMATION_DIALOG__CONFIRM_BANK_WIRE_WITHOUT_DETAILS,
  CONFIRMATION_DIALOG__DELETE_CREDIT_CARD,
  CONFIRMATION_DIALOG__REQUEST_REVENUE_CONVERSION,
} from '../deposit/constants/confirmationDialogs';
import { CONFIRMATION_DIALOG__DELETE_DIALOG } from '../dialog/constants/confirmationDialogs';
import { CONFIRMATION_DIALOG__DELETE_MESSAGES } from '../dialogMessage/constants/confirmationDialogs';
import {
  CONFIRMATION_DIALOG__REJECT_REAL_GIFT,
  CONFIRMATION_DIALOG__SEND_GIFT,
} from '../gift/constants/confirmationDialogs';
import {
  CONFIRMATION_DIALOG__CANCEL_INTERVIEW_FORM,
  CONFIRMATION_DIALOG__REJECT_INTERVIEW,
} from '../interview/constants/confirmationDialogs';
import { CONFIRMATION_DIALOG__DELETE_COMMENT } from '../mediaViewer/constants/confirmationDialogs';
import { CONFIRMATION_DIALOG__DELETE_POST } from '../ownPost/constants/confirmationDialogs';
import {
  CONFIRMATION_DIALOG__DELETE_COVER_PHOTO,
  CONFIRMATION_DIALOG__DELETE_EROSTORY,
  CONFIRMATION_DIALOG__DELETE_PRESENTATION,
  CONFIRMATION_DIALOG__DELETE_PRIVATE_PHOTO,
  CONFIRMATION_DIALOG__DELETE_PROFILE_PHOTO,
  CONFIRMATION_DIALOG__DELETE_PUBLIC_PHOTO,
  CONFIRMATION_DIALOG__DELETE_RECORDED_PRIVATE,
  CONFIRMATION_DIALOG__DELETE_UNDERWEAR,
  CONFIRMATION_DIALOG__DELETE_VIDEO,
} from '../ownSaleItem/constants/confirmationDialogs';
import { CONFIRMATION_DIALOG__DELETE_PARTNER } from '../partner/constants/confirmationDialogs';
import { CONFIRMATION_DIALOG__ABORT_SIGNUP } from '../registration/constants/confirmationDialogs';
import {
  CONFIRMATION_DIALOG__CANCEL_BANK_ACCOUNT,
  CONFIRMATION_DIALOG__CANCEL_PAYOUT_REQUEST,
} from '../revenue/constants/confirmationDialogs';
import {
  CONFIRMATION_DIALOG__CANCEL_UNDERWEAR_REQUEST,
  CONFIRMATION_DIALOG__MARK_UNDERWEAR_SENT,
} from '../underwear/constants/confirmationDialogs';


const GlobalErrorDialog = loadable(() => import('../core/components/GlobalErrorDialog'));

const AdultWarningDialog = loadable(() => import('./components/dialogs/AdultWarningDialog'));
const AddGeoFilterDialog = loadable(() => import('../account/components/dialogs/AddGeoFilterDialog'));
const AddWhiteListedUserDialog = loadable(() => import('../account/components/dialogs/AddWhiteListedUserDialog'));
const UpdateEmailDialog = loadable(() => import('../account/components/dialogs/UpdateEmailDialog'));
const CreateModelDialog = loadable(() => import('../agency/components/dialogs/CreateModelDialog'));
const InspectModelDialog = loadable(() => import('../agency/components/dialogs/InspectModelDialog'));
const UpdatePersonalInfoDialog = loadable(() => import('../agency/components/dialogs/UpdatePersonalInfoDialog'));
const AppointmentDialog = loadable(() => import('../appointment/components/dialogs/AppointmentDialog'));
const ApproveAppointmentDialog = loadable(() => import('../appointment/components/dialogs/ApproveAppointmentDialog'));
const CancelAppointmentDialog = loadable(() => import('../appointment/components/dialogs/CancelAppointmentDialog'));
const CreateAppointmentDialog = loadable(() => import('../appointment/components/dialogs/CreateAppointmentDialog'));
const AdventPrizesDialog = loadable(() => import('../auth/components/dialogs/AdventPrizesDialog'));
const FillNameDialog = loadable(() => import('../auth/components/dialogs/FillNameDialog'));
const GiftEventDialog = loadable(() => import('../auth/components/dialogs/GiftEventDialog'));
const LoginDialog = loadable(() => import('../auth/components/dialogs/LoginDialog'));
const MissedBonusesDialog = loadable(() => import('../auth/components/dialogs/MissedBonusesDialog'));
const PaidRouletteDialog = loadable(() => import('../auth/components/dialogs/PaidRouletteDialog'));
const PrizesDialog = loadable(() => import('../auth/components/dialogs/PrizesDialog'));
const RegistrationDialog = loadable(() => import('../auth/components/dialogs/RegistrationDialog'));
const RouletteDialog = loadable(() => import('../auth/components/dialogs/RouletteDialog'));
const SocialLoginErrorDialog = loadable(() => import('../auth/components/dialogs/SocialLoginErrorDialog'));
const TermsDialog = loadable(() => import('../auth/components/dialogs/TermsDialog'));
const UserRegistrationDialog = loadable(() => import('../auth/components/dialogs/UserRegistrationDialog'));
const AddBlockedUserDialog = loadable(() => import('../blackList/components/dialogs/AddBlockedUserDialog'));
const BuyPostDialog = loadable(() => import('../blog/components/dialogs/BuyPostDialog'));
const SubscribeDialog = loadable(() => import('../blog/components/dialogs/SubscribeDialog'));
const CryptoBonusDialog = loadable(() => import('../chatGallery/components/dialogs/CryptoBonusDialog'));
const WeekendBonusDialog = loadable(() => import('../chatGallery/components/dialogs/WeekendBonusDialog'));
const ImageDialog = loadable(() => import('../core/dialog/components/ImageDialog'));
const NotificationDialog = loadable(() => import('../core/dialog/components/NotificationDialog'));
const PresentationDialog = loadable(() => import('../core/dialog/components/PresentationDialog'));
const ConfirmBankWireDialog = loadable(() => import('../deposit/components/dialogs/ConfirmBankWireDialog'));
const GetCreditsDialog = loadable(() => import('../deposit/components/dialogs/GetCreditsDialog'));
const InvoiceDialog = loadable(() => import('../deposit/components/dialogs/InvoiceDialog'));
const PaymentResultNotificationDialog = loadable(() => import('../deposit/components/dialogs/PaymentResultNotificationDialog'));
const ReportDepositIssueDialog = loadable(() => import('../deposit/components/dialogs/ReportDepositIssueDialog'));
const UpdateAutoRefillSettingsDialog = loadable(() => import('../deposit/components/dialogs/UpdateAutoRefillSettingsDialog'));
const CreateDialogDialog = loadable(() => import('../dialog/components/dialogs/CreateDialogDialog'));
const BuyEmoticonDialog = loadable(() => import('../emoticonShop/components/dialogs/BuyEmoticonDialog'));
const GiftDialog = loadable(() => import('../gift/components/dialogs/GiftDialog'));
const RealGiftDialog = loadable(() => import('../gift/components/dialogs/RealGiftDialog'));
const SendGiftDialog = loadable(() => import('../gift/components/dialogs/SendGiftDialog'));
const CreateInterviewFormDialog = loadable(() => import('../interview/components/dialogs/CreateInterviewFormDialog'));
const InterviewFormDialog = loadable(() => import('../interview/components/dialogs/InterviewFormDialog'));
const ReportContentDialog = loadable(() => import('../legal/components/dialogs/ReportContentDialog'));
const ModelDialog = loadable(() => import('../model/components/dialogs/ModelDialog'));
const PersonalContactDialog = loadable(() => import('../model/components/dialogs/PersonalContactDialog'));
const CreatePostDialog = loadable(() => import('../ownPost/components/dialogs/CreatePostDialog'));
const SelectMediaDialog = loadable(() => import('../ownPost/components/dialogs/SelectMediaDialog'));
const UpdatePostDialog = loadable(() => import('../ownPost/components/dialogs/UpdatePostDialog'));
const CreateCoverPhotoDialog = loadable(() => import('../ownSaleItem/components/dialogs/CreateCoverPhotoDialog'));
const CreateErostoryDialog = loadable(() => import('../ownSaleItem/components/dialogs/CreateErostoryDialog'));
const CreatePresentationDialog = loadable(() => import('../ownSaleItem/components/dialogs/CreatePresentationDialog'));
const CreatePrivatePhotoDialog = loadable(() => import('../ownSaleItem/components/dialogs/CreatePrivatePhotoDialog'));
const CreateProfilePhotoDialog = loadable(() => import('../ownSaleItem/components/dialogs/CreateProfilePhotoDialog'));
const CreatePublicPhotoDialog = loadable(() => import('../ownSaleItem/components/dialogs/CreatePublicPhotoDialog'));
const CreateUnderwearDialog = loadable(() => import('../ownSaleItem/components/dialogs/CreateUnderwearDialog'));
const CreateVideoDialog = loadable(() => import('../ownSaleItem/components/dialogs/CreateVideoDialog'));
const HelpDialog = loadable(() => import('../ownSaleItem/components/dialogs/HelpDialog'));
const PostRecordedPrivateDialog = loadable(() => import('../ownSaleItem/components/dialogs/PostRecordedPrivateDialog'));
const UpdateErostoryDialog = loadable(() => import('../ownSaleItem/components/dialogs/UpdateErostoryDialog'));
const UpdatePersonalContactDialog = loadable(() => import('../ownSaleItem/components/dialogs/UpdatePersonalContactDialog'));
const UpdatePresentationDialog = loadable(() => import('../ownSaleItem/components/dialogs/UpdatePresentationDialog'));
const UpdatePrivatePhotoDialog = loadable(() => import('../ownSaleItem/components/dialogs/UpdatePrivatePhotoDialog'));
const UpdateProfilePhotoDialog = loadable(() => import('../ownSaleItem/components/dialogs/UpdateProfilePhotoDialog'));
const UpdatePublicPhotoDialog = loadable(() => import('../ownSaleItem/components/dialogs/UpdatePublicPhotoDialog'));
const UpdateUnderwearDialog = loadable(() => import('../ownSaleItem/components/dialogs/UpdateUnderwearDialog'));
const UpdateVideoDialog = loadable(() => import('../ownSaleItem/components/dialogs/UpdateVideoDialog'));
const CreatePartnerDialog = loadable(() => import('../partner/components/dialogs/CreatePartnerDialog'));
const UpdatePartnerDialog = loadable(() => import('../partner/components/dialogs/UpdatePartnerDialog'));
const AgreementDialog = loadable(() => import('../registration/components/dialogs/AgreementDialog'));
const ProfilePhotoDialog = loadable(() => import('../registration/components/dialogs/ProfilePhotoDialog'));
const DeclineDetailsDialog = loadable(() => import('../revenue/components/dialogs/DeclineDetailsDialog'));
const OrderXCardDialog = loadable(() => import('../revenue/components/dialogs/OrderXCardDialog'));
const RequestPayoutDialog = loadable(() => import('../revenue/components/dialogs/RequestPayoutDialog'));
const SendPayoutComplaintDialog = loadable(() => import('../revenue/components/dialogs/SendPayoutComplaintDialog'));
const SubmitFiscalNumberDialog = loadable(() => import('../revenue/components/dialogs/SubmitFiscalNumberDialog'));
const UpdateBankAccountDetailsDialog = loadable(() => import('../revenue/components/dialogs/UpdateBankAccountDetailsDialog'));
const UpdateRevolutPhoneNumberDialog = loadable(() => import('../revenue/components/dialogs/UpdateRevolutPhoneNumberDialog'));
const BoughtUnderwearDialog = loadable(() => import('../saleItem/components/dialogs/BoughtUnderwearDialog'));
const BuySaleItemDialog = loadable(() => import('../saleItem/components/dialogs/BuySaleItemDialog'));
const BuySimpleSaleItemDialog = loadable(() => import('../saleItem/components/dialogs/BuySimpleSaleItemDialog'));
const BuyUnderwearDialog = loadable(() => import('../saleItem/components/dialogs/BuyUnderwearDialog'));
const VideoDialog = loadable(() => import('../saleItem/components/dialogs/VideoDialog'));

const DeleteGeoFilter = loadable(() => import('../account/components/confirmationDialogs/DeleteGeoFilter'));
const DeleteWhiteListedUser = loadable(() => import('../account/components/confirmationDialogs/DeleteWhiteListedUser'));
const DisconnectFromTelegram = loadable(() => import('../account/components/confirmationDialogs/DisconnectFromTelegram'));
const GenerateVideochatExternalApplicationSettings = loadable(() => import('../account/components/confirmationDialogs/GenerateVideochatExternalApplicationSettings'));
const UpdateModelStatus = loadable(() => import('../agency/components/confirmationDialogs/UpdateModelStatus'));
const CreateAppointment = loadable(() => import('../appointment/components/confirmationDialogs/CreateAppointment'));
const Logout = loadable(() => import('../auth/components/confirmationDialogs/Logout'));
const SpinRoulette = loadable(() => import('../auth/components/confirmationDialogs/SpinRoulette'));
const CancelRevenueConversionRequest = loadable(() => import('../balance/components/confirmationDialogs/CancelRevenueConversionRequest'));
const DeleteBannedUser = loadable(() => import('../bannedUser/components/confirmationDialogs/DeleteBannedUser'));
const AddBlockedUser = loadable(() => import('../blackList/components/confirmationDialogs/AddBlockedUser'));
const DeleteBlockedUser = loadable(() => import('../blackList/components/confirmationDialogs/DeleteBlockedUser'));
const Unsubscribe = loadable(() => import('../blog/components/confirmationDialogs/Unsubscribe'));
const CancelRewardRequest = loadable(() => import('../deposit/components/confirmationDialogs/CancelRewardRequest'));
const ConfirmBankWireWithoutDetails = loadable(() => import('../deposit/components/confirmationDialogs/ConfirmBankWireWithoutDetails'));
const DeleteCreditCard = loadable(() => import('../deposit/components/confirmationDialogs/DeleteCreditCard'));
const RequestRevenueConversion = loadable(() => import('../deposit/components/confirmationDialogs/RequestRevenueConversion'));
const DeleteDialog = loadable(() => import('../dialog/components/confirmationDialogs/DeleteDialog'));
const DeleteMessages = loadable(() => import('../dialogMessage/components/confirmationDialogs/DeleteMessages'));
const RejectRealGift = loadable(() => import('../gift/components/confirmationDialogs/RejectRealGift'));
const SendGift = loadable(() => import('../gift/components/confirmationDialogs/SendGift'));
const CancelInterviewForm = loadable(() => import('../interview/components/confirmationDialogs/CancelInterviewForm'));
const RejectInterview = loadable(() => import('../interview/components/confirmationDialogs/RejectInterview'));
const DeleteComment = loadable(() => import('../mediaViewer/components/confirmationDialogs/DeleteComment'));
const DeletePost = loadable(() => import('../ownPost/components/confirmationDialogs/DeletePost'));
const DeleteCoverPhoto = loadable(() => import('../ownSaleItem/components/confirmationDialogs/DeleteCoverPhoto'));
const DeleteErostory = loadable(() => import('../ownSaleItem/components/confirmationDialogs/DeleteErostory'));
const DeletePresentation = loadable(() => import('../ownSaleItem/components/confirmationDialogs/DeletePresentation'));
const DeletePrivatePhoto = loadable(() => import('../ownSaleItem/components/confirmationDialogs/DeletePrivatePhoto'));
const DeleteProfilePhoto = loadable(() => import('../ownSaleItem/components/confirmationDialogs/DeleteProfilePhoto'));
const DeletePublicPhoto = loadable(() => import('../ownSaleItem/components/confirmationDialogs/DeletePublicPhoto'));
const DeleteRecordedPrivate = loadable(() => import('../ownSaleItem/components/confirmationDialogs/DeleteRecordedPrivate'));
const DeleteUnderwear = loadable(() => import('../ownSaleItem/components/confirmationDialogs/DeleteUnderwear'));
const DeleteVideo = loadable(() => import('../ownSaleItem/components/confirmationDialogs/DeleteVideo'));
const DeletePartner = loadable(() => import('../partner/components/confirmationDialogs/DeletePartner'));
const AbortSignup = loadable(() => import('../registration/components/confirmationDialogs/AbortSignup'));
const CancelBankAccount = loadable(() => import('../revenue/components/confirmationDialogs/CancelBankAccount'));
const CancelPayoutRequest = loadable(() => import('../revenue/components/confirmationDialogs/CancelPayoutRequest'));
const CancelUnderwearRequest = loadable(() => import('../underwear/components/confirmationDialogs/CancelUnderwearRequest'));
const MarkUnderwearSent = loadable(() => import('../underwear/components/confirmationDialogs/MarkUnderwearSent'));

export default {
  [MODAL_DIALOG__ERROR]: GlobalErrorDialog,
  [MODAL_DIALOG__ADULT_WARNING]: AdultWarningDialog,
  [MODAL_DIALOG__ADD_GEO_FILTER]: AddGeoFilterDialog,
  [MODAL_DIALOG__ADD_WHITELISTED_USER]: AddWhiteListedUserDialog,
  [MODAL_DIALOG__UPDATE_EMAIL]: UpdateEmailDialog,
  [MODAL_DIALOG__CREATE_MODEL]: CreateModelDialog,
  [MODAL_DIALOG__INSPECT_MODEL]: InspectModelDialog,
  [MODAL_DIALOG__UPDATE_PERSONAL_INFO]: UpdatePersonalInfoDialog,
  [MODAL_DIALOG__APPOINTMENT]: AppointmentDialog,
  [MODAL_DIALOG__APPROVE_APPOINTMENT]: ApproveAppointmentDialog,
  [MODAL_DIALOG__CANCEL_APPOINTMENT]: CancelAppointmentDialog,
  [MODAL_DIALOG__CREATE_APPOINTMENT]: CreateAppointmentDialog,
  [MODAL_DIALOG__ADVENT_PRIZES]: AdventPrizesDialog,
  [MODAL_DIALOG__GIFT_EVENT]: GiftEventDialog,
  [MODAL_DIALOG__FILL_NAME]: FillNameDialog,
  [MODAL_DIALOG__LOGIN]: LoginDialog,
  [MODAL_DIALOG__MISSED_BONUSES]: MissedBonusesDialog,
  [MODAL_DIALOG__PAID_ROULETTE]: PaidRouletteDialog,
  [MODAL_DIALOG__PRIZES]: PrizesDialog,
  [MODAL_DIALOG__REGISTRATION]: RegistrationDialog,
  [MODAL_DIALOG__REPORT_CONTENT]: ReportContentDialog,
  [MODAL_DIALOG__ROULETTE]: RouletteDialog,
  [MODAL_DIALOG__SOCIAL_LOGIN_ERROR]: SocialLoginErrorDialog,
  [MODAL_DIALOG__TERMS]: TermsDialog,
  [MODAL_DIALOG__USER_REGISTRATION]: UserRegistrationDialog,
  [MODAL_DIALOG__ADD_BLOCKED_USER]: AddBlockedUserDialog,
  [MODAL_DIALOG__BUY_POST]: BuyPostDialog,
  [MODAL_DIALOG__SUBSCRIBE]: SubscribeDialog,
  [MODAL_DIALOG__CRYPTO_BONUS]: CryptoBonusDialog,
  [MODAL_DIALOG__WEEKEND_BONUS]: WeekendBonusDialog,
  [MODAL_DIALOG__IMAGE]: ImageDialog,
  [MODAL_DIALOG__NOTIFICATION]: NotificationDialog,
  [MODAL_DIALOG__PRESENTATION]: PresentationDialog,
  [MODAL_DIALOG__CONFIRM_BANK_WIRE]: ConfirmBankWireDialog,
  [MODAL_DIALOG__GET_CREDITS]: GetCreditsDialog,
  [MODAL_DIALOG__INVOICE]: InvoiceDialog,
  [MODAL_DIALOG__PAYMENT_RESULT_NOTIFICATION]: PaymentResultNotificationDialog,
  [MODAL_DIALOG__REPORT_DEPOSIT_ISSUE]: ReportDepositIssueDialog,
  [MODAL_DIALOG__UPDATE_AUTO_REFILL_SETTINGS]: UpdateAutoRefillSettingsDialog,
  [MODAL_DIALOG__CREATE_DIALOG]: CreateDialogDialog,
  [MODAL_DIALOG__BUY_EMOTICON]: BuyEmoticonDialog,
  [MODAL_DIALOG__GIFT]: GiftDialog,
  [MODAL_DIALOG__REAL_GIFT]: RealGiftDialog,
  [MODAL_DIALOG__SEND_GIFT]: SendGiftDialog,
  [MODAL_DIALOG__CREATE_INTERVIEW_FORM]: CreateInterviewFormDialog,
  [MODAL_DIALOG__INTERVIEW_FORM]: InterviewFormDialog,
  [MODAL_DIALOG__MODEL]: ModelDialog,
  [MODAL_DIALOG__PERSONAL_CONTACT]: PersonalContactDialog,
  [MODAL_DIALOG__CREATE_POST]: CreatePostDialog,
  [MODAL_DIALOG__SELECT_MEDIA]: SelectMediaDialog,
  [MODAL_DIALOG__UPDATE_POST]: UpdatePostDialog,
  [MODAL_DIALOG__CREATE_COVER_PHOTO]: CreateCoverPhotoDialog,
  [MODAL_DIALOG__CREATE_EROSTORY]: CreateErostoryDialog,
  [MODAL_DIALOG__CREATE_PRESENTATION]: CreatePresentationDialog,
  [MODAL_DIALOG__CREATE_PRIVATE_PHOTO]: CreatePrivatePhotoDialog,
  [MODAL_DIALOG__CREATE_PROFILE_PHOTO]: CreateProfilePhotoDialog,
  [MODAL_DIALOG__CREATE_PUBLIC_PHOTO]: CreatePublicPhotoDialog,
  [MODAL_DIALOG__CREATE_UNDERWEAR]: CreateUnderwearDialog,
  [MODAL_DIALOG__CREATE_VIDEO]: CreateVideoDialog,
  [MODAL_DIALOG__HELP]: HelpDialog,
  [MODAL_DIALOG__POST_RECORDED_PRIVATE]: PostRecordedPrivateDialog,
  [MODAL_DIALOG__UPDATE_EROSTORY]: UpdateErostoryDialog,
  [MODAL_DIALOG__UPDATE_PERSONAL_CONTACT]: UpdatePersonalContactDialog,
  [MODAL_DIALOG__UPDATE_PRESENTATION]: UpdatePresentationDialog,
  [MODAL_DIALOG__UPDATE_PRIVATE_PHOTO]: UpdatePrivatePhotoDialog,
  [MODAL_DIALOG__UPDATE_PROFILE_PHOTO]: UpdateProfilePhotoDialog,
  [MODAL_DIALOG__UPDATE_PUBLIC_PHOTO]: UpdatePublicPhotoDialog,
  [MODAL_DIALOG__UPDATE_UNDERWEAR]: UpdateUnderwearDialog,
  [MODAL_DIALOG__UPDATE_VIDEO]: UpdateVideoDialog,
  [MODAL_DIALOG__CREATE_PARTNER]: CreatePartnerDialog,
  [MODAL_DIALOG__UPDATE_PARTNER]: UpdatePartnerDialog,
  [MODAL_DIALOG__AGREEMENT]: AgreementDialog,
  [MODAL_DIALOG__PROFILE_PHOTO]: ProfilePhotoDialog,
  [MODAL_DIALOG__DECLINE_DETAILS]: DeclineDetailsDialog,
  [MODAL_DIALOG__ORDER_XCARD]: OrderXCardDialog,
  [MODAL_DIALOG__REQUEST_PAYOUT]: RequestPayoutDialog,
  [MODAL_DIALOG__SEND_PAYOUT_COMPLAINT]: SendPayoutComplaintDialog,
  [MODAL_DIALOG__SUBMIT_FISCAL_NUMBER]: SubmitFiscalNumberDialog,
  [MODAL_DIALOG__UPDATE_BANK_ACCOUNT_DETAILS]: UpdateBankAccountDetailsDialog,
  [MODAL_DIALOG__UPDATE_REVOLUT_PHONE_NUMBER]: UpdateRevolutPhoneNumberDialog,
  [MODAL_DIALOG__BOUGHT_UNDERWEAR]: BoughtUnderwearDialog,
  [MODAL_DIALOG__BUY_SALE_ITEM]: BuySaleItemDialog,
  [MODAL_DIALOG__BUY_SIMPLE_SALE_ITEM]: BuySimpleSaleItemDialog,
  [MODAL_DIALOG__BUY_UNDERWEAR]: BuyUnderwearDialog,
  [MODAL_DIALOG__VIDEO]: VideoDialog,

  [CONFIRMATION_DIALOG__DELETE_GEO_FILTER]: DeleteGeoFilter,
  [CONFIRMATION_DIALOG__DELETE_WHITELISTED_USER]: DeleteWhiteListedUser,
  [CONFIRMATION_DIALOG__DISCONNECT_FROM_TELEGRAM]: DisconnectFromTelegram,
  [CONFIRMATION_DIALOG__GENERATE_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS]:
    GenerateVideochatExternalApplicationSettings,
  [CONFIRMATION_DIALOG__UPDATE_MODEL_STATUS]: UpdateModelStatus,
  [CONFIRMATION_DIALOG__CREATE_APPOINTMENT]: CreateAppointment,
  [CONFIRMATION_DIALOG__LOGOUT]: Logout,
  [CONFIRMATION_DIALOG__SPIN_ROULETTE]: SpinRoulette,
  [CONFIRMATION_DIALOG__CANCEL_REVENUE_CONVERSION_REQUEST]: CancelRevenueConversionRequest,
  [CONFIRMATION_DIALOG__DELETE_BANNED_USER]: DeleteBannedUser,
  [CONFIRMATION_DIALOG__ADD_BLOCKED_USER]: AddBlockedUser,
  [CONFIRMATION_DIALOG__DELETE_BLOCKED_USER]: DeleteBlockedUser,
  [CONFIRMATION_DIALOG__UNSUBSCRIBE]: Unsubscribe,
  [CONFIRMATION_DIALOG__CANCEL_REWARD_REQUEST]: CancelRewardRequest,
  [CONFIRMATION_DIALOG__CONFIRM_BANK_WIRE_WITHOUT_DETAILS]: ConfirmBankWireWithoutDetails,
  [CONFIRMATION_DIALOG__DELETE_CREDIT_CARD]: DeleteCreditCard,
  [CONFIRMATION_DIALOG__REQUEST_REVENUE_CONVERSION]: RequestRevenueConversion,
  [CONFIRMATION_DIALOG__DELETE_DIALOG]: DeleteDialog,
  [CONFIRMATION_DIALOG__DELETE_MESSAGES]: DeleteMessages,
  [CONFIRMATION_DIALOG__REJECT_REAL_GIFT]: RejectRealGift,
  [CONFIRMATION_DIALOG__SEND_GIFT]: SendGift,
  [CONFIRMATION_DIALOG__CANCEL_INTERVIEW_FORM]: CancelInterviewForm,
  [CONFIRMATION_DIALOG__REJECT_INTERVIEW]: RejectInterview,
  [CONFIRMATION_DIALOG__DELETE_COMMENT]: DeleteComment,
  [CONFIRMATION_DIALOG__DELETE_POST]: DeletePost,
  [CONFIRMATION_DIALOG__DELETE_COVER_PHOTO]: DeleteCoverPhoto,
  [CONFIRMATION_DIALOG__DELETE_EROSTORY]: DeleteErostory,
  [CONFIRMATION_DIALOG__DELETE_PRESENTATION]: DeletePresentation,
  [CONFIRMATION_DIALOG__DELETE_PRIVATE_PHOTO]: DeletePrivatePhoto,
  [CONFIRMATION_DIALOG__DELETE_PROFILE_PHOTO]: DeleteProfilePhoto,
  [CONFIRMATION_DIALOG__DELETE_PUBLIC_PHOTO]: DeletePublicPhoto,
  [CONFIRMATION_DIALOG__DELETE_RECORDED_PRIVATE]: DeleteRecordedPrivate,
  [CONFIRMATION_DIALOG__DELETE_UNDERWEAR]: DeleteUnderwear,
  [CONFIRMATION_DIALOG__DELETE_VIDEO]: DeleteVideo,
  [CONFIRMATION_DIALOG__DELETE_PARTNER]: DeletePartner,
  [CONFIRMATION_DIALOG__ABORT_SIGNUP]: AbortSignup,
  [CONFIRMATION_DIALOG__CANCEL_BANK_ACCOUNT]: CancelBankAccount,
  [CONFIRMATION_DIALOG__CANCEL_PAYOUT_REQUEST]: CancelPayoutRequest,
  [CONFIRMATION_DIALOG__CANCEL_UNDERWEAR_REQUEST]: CancelUnderwearRequest,
  [CONFIRMATION_DIALOG__MARK_UNDERWEAR_SENT]: MarkUnderwearSent,
};
