import { deepMergeStyles } from '../index';

import { RRA_ACTION_LINK } from 'react-redux-app/lib/modules/actionLink/constants';
import { RRA_BUTTON } from 'react-redux-app/lib/modules/button/constants';
import { RRA_BUTTON_SET } from 'react-redux-app/lib/modules/buttonSet/constants';
import { RRA_CONTEXT_MENU_LINK } from 'react-redux-app/lib/modules/contextMenuLink/constants';
import { RRA_DROP_DOWN_MENU } from 'react-redux-app/lib/modules/dropDownMenu/constants';
import { RRA_GRID_PAGINATION } from 'react-redux-app/lib/modules/gridPagination/constants';
import { RRA_GRID_PLACEHOLDER } from 'react-redux-app/lib/modules/grid/constants';
import { RRA_HEADING } from 'react-redux-app/lib/modules/heading/constants';
import { RRA_LINK } from 'react-redux-app/lib/modules/link/constants';
import { RRA_MODAL } from 'react-redux-app/lib/modules/modal/constants';
import { RRA_PAGINATION } from 'react-redux-app/lib/modules/pagination/constants';
import { RRA_PASSWORD_STRENGTH_METER } from 'react-redux-app/lib/modules/passwordStrengthMeter/constants';
import { RRA_PROGRESS_BAR } from 'react-redux-app/lib/modules/progressBar/constants';
import { RRA_SPINNER_CIRCULAR_BOUNCE } from 'react-redux-app/lib/modules/circularBounceSpinner/constants';
import {
  RRA_TABLE,
  RRA_VERT_TABLE,
} from 'react-redux-app/lib/modules/table/constants';
import { RRA_TABLE_SUMMARY } from 'react-redux-app/lib/modules/tableSummary/constants';

import {
  RRA_FORM_AUTOCOMPLETE,
  RRA_FORM_CHECKBOX,
  RRA_FORM_DAY_MONTH_YEAR,
  RRA_FORM_ERROR,
  RRA_FORM_FIELD,
  RRA_FORM_INPUT,
  RRA_FORM_INPUT_PASSWORD,
  RRA_FORM_INPUT_TEXT_WITH_ADDON,
  RRA_FORM_MULTI_SELECT,
  RRA_FORM_RADIO_BUTTON,
  RRA_FORM_SCHEDULE,
  RRA_FORM_TEXTAREA,
} from 'react-redux-app/lib/modules/form/constants';

import {
  RRA_MEDIA_VIEWER,
  RRA_MEDIA_VIEWER__CONTROLS,
  RRA_MEDIA_VIEWER__CURRENT_POSITION,
  RRA_MEDIA_VIEWER__NAVIGATION,
} from 'react-redux-app/lib/modules/mediaViewer/constants';

import {
  FOOTER,
  GO_TO_VIDEOCHAT,
  HEADER,
  HIGHLIGHTED_NUMBER,
  MENU_LINK,
  USER_MENU_HEADER,
  WEB_SOCKET,
} from '../../modules/app/constants/theme';

import { PROFILE_PHOTO } from '../../modules/blog/constants/theme';

import {
  ACTIVE_STATUS,
  BORDERED_AREA,
  BUTTON__MATERIAL_ICON,
  BUTTON__PINK,
  BUTTON__WHITE,
  CONTENT_PAGINATION,
  COPYABLE_VALUE,
  DRAG_AREA,
  FILTER_PANEL,
  FORMATTED_DATE_TIME,
  GALLERY_CATEGORIES,
  GALLERY_NAVIGATION,
  GALLERY_ITEM,
  GALLERY_ITEM_TITLE,
  GALLERY_VIDEO_PLACEHOLDER,
  GRID_COLS_SIZE_CONTROL,
  HTML_TEXT,
  ICON__ACTION,
  ICON__PHOTO,
  ICON__SEARCH,
  LABEL,
  LIST_GALLERY,
  LOADABLE_ITEMS,
  MODEL_INFO,
  NO_RECORDS_PLACEHOLDER,
  PAGE_SPINNER,
  PHOTO_PREVIEW_WITH_DRAG_AREA,
  TAB,
  UPLOAD_ERROR,
  USER_WITH_FAVORITE_MARK_AND_INFO,
  VIDEO_BACKGROUND,
  VIDEO_PREVIEW,
  VIEW_NUMBER,
} from '../../modules/core/constants/theme';

import {
  BUY_CREDITS_LOCALLY_FORM,
  CRYPTO_WALLET_ADDRESS,
  GET_CREDITS,
} from '../../modules/deposit/constants/theme';

import { DIALOG_GALLERY_ITEM } from '../../modules/dialog/constants/theme';

import {
  DIALOG,
  DIALOG_MESSAGES,
  DIALOG_MESSAGE,
  SEND_MESSAGE_FORM,
} from '../../modules/dialogMessage/constants/theme';

import {
  GIFTS,
  SEND_GIFT_DIALOG,
} from '../../modules/gift/constants/theme';

import {
  MEDIA_VIEWER_COMMENTS,
  MEDIA_VIEWER_MEDIA,
} from '../../modules/mediaViewer/constants/theme';

import {
  MODEL_RATING,
  PERSONAL_CONTACTS,
  WEBCAM_ACTIVITY,
} from '../../modules/model/constants/theme';

import { NEWS_FEED_ITEM } from '../../modules/newsFeed/constants/theme';

import {
  COMMENT_NUMBER,
  CONVERTING_STATUS,
  VIDEO_PLACEHOLDER,
} from '../../modules/ownSaleItem/constants/theme';

import { PROFILE } from '../../modules/profile/constants/theme';

import {
  BONUS_PERCENT,
  REQUEST_PAYOUT_FORM,
} from '../../modules/revenue/constants/theme';

import {
  BUY_SALE_ITEM,
  INVERSED_COMMENT_NUMBER,
} from '../../modules/saleItem/constants/theme';

import baseActionLinkTheme from '../../styles/base/libs/react-redux-app/ActionLink/index.css.json';
import actionLinkTheme from '../../styles/fetish/libs/react-redux-app/ActionLink/index.css.json';
import baseButtonTheme from '../../styles/base/libs/react-redux-app/Button/index.css.json';
import buttonTheme from '../../styles/fetish/libs/react-redux-app/Button/index.css.json';
import baseButtonSetTheme from '../../styles/base/libs/react-redux-app/ButtonSet/index.css.json';
import baseContextMenuLinkTheme from '../../styles/base/libs/react-redux-app/ContextMenuLink/index.css.json';
import contextMenuLinkTheme from '../../styles/fetish/libs/react-redux-app/ContextMenuLink/index.css.json';
import baseDropDownMenuTheme from '../../styles/base/libs/react-redux-app/DropDownMenu/index.css.json';
import dropDownMenuTheme from '../../styles/fetish/libs/react-redux-app/DropDownMenu/index.css.json';
import baseGridPaginationTheme from '../../styles/base/libs/react-redux-app/GridPagination/index.css.json';
import gridPaginationTheme from '../../styles/fetish/libs/react-redux-app/GridPagination/index.css.json';
import baseGridPlaceholerTheme from '../../styles/base/libs/react-redux-app/GridPlaceholder/index.css.json';
import baseHeadingTheme from '../../styles/base/libs/react-redux-app/Heading/index.css.json';
import headingTheme from '../../styles/fetish/libs/react-redux-app/Heading/index.css.json';
import baseLinkTheme from '../../styles/base/libs/react-redux-app/Link/index.css.json';
import linkTheme from '../../styles/fetish/libs/react-redux-app/Link/index.css.json';
import baseModalTheme from '../../styles/base/libs/react-redux-app/Modal/index.css.json';
import modalTheme from '../../styles/fetish/libs/react-redux-app/Modal/index.css.json';
import basePaginationTheme from '../../styles/base/libs/react-redux-app/Pagination/index.css.json';
import paginationTheme from '../../styles/fetish/libs/react-redux-app/Pagination/index.css.json';
import passwordStrengthMeter from '../../styles/fetish/libs/react-redux-app/PasswordStrengthMeter/index.css.json';
import baseProgressBarTheme from '../../styles/base/libs/react-redux-app/ProgressBar/index.css.json';
import progressBarTheme from '../../styles/fetish/libs/react-redux-app/ProgressBar/index.css.json';
import circularBounceSpinnerTheme from '../../styles/fetish/libs/react-redux-app/CircularBounceSpinner/index.css.json';
import baseTableTheme from '../../styles/base/libs/react-redux-app/table/Table/index.css.json';
import tableTheme from '../../styles/fetish/libs/react-redux-app/table/Table/index.css.json';
import baseVertTableTheme from '../../styles/base/libs/react-redux-app/table/VertTable/index.css.json';
import vertTableTheme from '../../styles/fetish/libs/react-redux-app/table/VertTable/index.css.json';
import baseTableSummaryTheme from '../../styles/base/libs/react-redux-app/TableSummary/index.css.json';
import tableSummaryTheme from '../../styles/fetish/libs/react-redux-app/TableSummary/index.css.json';

import baseAutocompleteTheme from '../../styles/base/libs/react-redux-app/form/Autocomplete/index.css.json';
import autocompleteTheme from '../../styles/fetish/libs/react-redux-app/form/Autocomplete/index.css.json';
import baseCheckboxTheme from '../../styles/base/libs/react-redux-app/form/Checkbox/index.css.json';
import checkboxTheme from '../../styles/fetish/libs/react-redux-app/form/Checkbox/index.css.json';
import baseDayMonthYearTheme from '../../styles/base/libs/react-redux-app/form/DayMonthYear/index.css.json';
import baseFieldTheme from '../../styles/base/libs/react-redux-app/form/Field/index.css.json';
import fieldTheme from '../../styles/fetish/libs/react-redux-app/form/Field/index.css.json';
import baseFormErrorTheme from '../../styles/base/libs/react-redux-app/form/FormError/index.css.json';
import baseInputTheme from '../../styles/base/libs/react-redux-app/form/Input/index.css.json';
import inputTheme from '../../styles/fetish/libs/react-redux-app/form/Input/index.css.json';
import baseInputPasswordTheme from '../../styles/base/libs/react-redux-app/form/InputPassword/index.css.json';
import inputPasswordTheme from '../../styles/fetish/libs/react-redux-app/form/InputPassword/index.css.json';
import baseInputTextWithAddonTheme from '../../styles/base/libs/react-redux-app/form/InputTextWithAddon/index.css.json';
import inputTextWithAddonTheme from '../../styles/fetish/libs/react-redux-app/form/InputTextWithAddon/index.css.json';
import baseMultiSelectTheme from '../../styles/base/libs/react-redux-app/form/MultiSelect/index.css.json';
import multiSelectTheme from '../../styles/fetish/libs/react-redux-app/form/MultiSelect/index.css.json';
import baseRadioButtonTheme from '../../styles/base/libs/react-redux-app/form/RadioButton/index.css.json';
import radioButtonTheme from '../../styles/fetish/libs/react-redux-app/form/RadioButton/index.css.json';
import baseScheduleTheme from '../../styles/base/libs/react-redux-app/form/Schedule/index.css.json';
import scheduleTheme from '../../styles/fetish/libs/react-redux-app/form/Schedule/index.css.json';
import baseTextareaTheme from '../../styles/base/libs/react-redux-app/form/Textarea/index.css.json';
import textareaTheme from '../../styles/fetish/libs/react-redux-app/form/Textarea/index.css.json';

import baseMediaViewerTheme from '../../styles/base/libs/react-redux-app/mediaViewer/MediaViewer/index.css.json';
import mediaViewerTheme from '../../styles/fetish/libs/react-redux-app/mediaViewer/MediaViewer/index.css.json';
import baseMediaViewerNavigationTheme from '../../styles/base/libs/react-redux-app/mediaViewer/Navigation/index.css.json';
import mediaViewerNavigationTheme from '../../styles/fetish/libs/react-redux-app/mediaViewer/Navigation/index.css.json';
import baseMediaViewerCurrentPositionTheme from '../../styles/base/libs/react-redux-app/mediaViewer/CurrentItemPosition/index.css.json';
import mediaViewerCurrentPositionTheme from '../../styles/fetish/libs/react-redux-app/mediaViewer/CurrentItemPosition/index.css.json';
import baseMediaViewerControlsTheme from '../../styles/base/libs/react-redux-app/mediaViewer/MediaViewerControls/index.css.json';
import mediaViewerControlsTheme from '../../styles/fetish/libs/react-redux-app/mediaViewer/MediaViewerControls/index.css.json';

import footerTheme from '../../modules/app/components/Footer/styles/fetish/index.css.json';
import goToVideochatTheme from '../../modules/app/components/GoToVideochat/styles/fetish/index.css.json';
import headerTheme from '../../modules/app/components/Header/styles/fetish/index.css.json';
import highlightedNumberTheme from '../../modules/app/components/HighlightedNumber/styles/fetish/index.css.json';
import menuLinkTheme from '../../modules/app/components/MenuLink/styles/fetish/index.css.json';
import userMenuHeaderTheme from '../../modules/app/components/UserMenuHeader/styles/fetish/index.css.json';
import webSocketTheme from '../../modules/app/components/WebSocket/styles/fetish/index.css.json';

import profilePhotoTheme from '../../modules/blog/components/ProfilePhoto/styles/fetish/index.css.json';

import activeStatusTheme from '../../modules/core/components/ActiveStatus/styles/fetish/index.css.json';
import borderedAreaTheme from '../../modules/core/components/BorderedArea/styles/fetish/index.css.json';
import buttonMaterialIconTheme from '../../modules/core/components/buttons/MaterialIconButton/styles/fetish/index.css.json';
import buttonPinkTheme from '../../modules/core/components/buttons/PinkButton/styles/fetish/index.css.json';
import buttonWhiteTheme from '../../modules/core/components/buttons/WhiteButton/styles/fetish/index.css.json';
import contentPaginationTheme from '../../modules/core/components/ContentPagination/styles/fetish/index.css.json';
import copyableValueTheme from '../../modules/core/components/CopyableValue/styles/fetish/index.css.json';
import dragAreaTheme from '../../modules/core/components/DragArea/styles/fetish/index.css.json';
import filterPanelTheme from '../../modules/core/components/filterPanelHOC/styles/fetish/index.css.json';
import formattedDateTimeTheme from '../../modules/core/components/FormattedDateTime/styles/fetish/index.css.json';
import galleryCategoriesTheme from '../../modules/core/galleryWithCategories/components/GalleryCategories/styles/fetish/index.css.json';
import galleryNavigationTheme from '../../modules/core/galleryWithCategories/components/GalleryNavigation/styles/fetish/index.css.json';
import galleryItemTheme from '../../modules/core/components/galleries/GalleryItem/styles/fetish/index.css.json';
import galleryItemTitleTheme from '../../modules/core/components/galleries/GalleryItemTitle/styles/fetish/index.css.json';
import galleryVideoPlaceholderTheme from '../../modules/core/components/GalleryVideoPlaceholder/styles/fetish/index.css.json';
import gridColsSizeControlTheme from '../../modules/core/gridColsSizeControl/components/GridColsSizeControl/styles/fetish/index.css.json';
import htmlTextTheme from '../../modules/core/components/HtmlText/styles/fetish/index.css.json';
import iconActionTheme from '../../modules/core/components/icons/ActionIcon/styles/fetish/index.css.json';
import iconPhotoTheme from '../../modules/core/components/icons/PhotoIcon/styles/fetish/index.css.json';
import iconSearchTheme from '../../modules/core/components/icons/SearchIcon/styles/fetish/index.css.json';
import labelTheme from '../../modules/core/components/Label/styles/fetish/index.css.json';
import listGalleryTheme from '../../modules/core/components/galleries/ListGallery/styles/fetish/index.css.json';
import loadableItemsTheme from '../../modules/core/loadableItems/components/LoadableItems/styles/fetish/index.css.json';
import modelInfoTheme from '../../modules/core/components/ModelInfo/styles/fetish/index.css.json';
import noRecordsPlaceholderTheme from '../../modules/core/components/NoRecordsPlaceholder/styles/fetish/index.css.json';
import pageSpinnerTheme from '../../modules/core/components/PageSpinner/styles/fetish/index.css.json';
import photoPreviewWithDragAreaTheme from '../../modules/core/components/PhotoPreviewWithDragArea/styles/fetish/index.css.json';
import tabTheme from '../../modules/core/tabs/components/CustomTab/styles/fetish/index.css.json';
import uploadErrorTheme from '../../modules/core/sequentialMediaUploader/components/UploadError/styles/fetish/index.css.json';
import userWithFavoriteMarkAndInfoTheme from '../../modules/core/components/UserWithFavoriteMarkAndInfo/styles/fetish/index.css.json';
import videoBackgroundTheme from '../../modules/core/components/VideoBackground/styles/fetish/index.css.json';
import videoPreviewTheme from '../../modules/core/videoPreview/components/videoPreviewHOC/styles/fetish/index.css.json';
import viewNumberTheme from '../../modules/core/components/ViewNumber/styles/fetish/index.css.json';

import buyCreditsLocallyFormTheme from '../../modules/deposit/components/forms/BuyCreditsLocallyForm/styles/fetish/index.css.json';
import cryptoWalletAddressTheme from '../../modules/deposit/components/CryptoWalletAddress/styles/fetish/index.css.json';
import getCreditsTheme from '../../modules/deposit/components/GetCredits/styles/fetish/index.css.json';

import dialogGalleryItemTheme from '../../modules/dialog/components/Dialog/styles/fetish/index.css.json';

import dialogTheme from '../../modules/dialogMessage/components/Dialog/styles/fetish/index.css.json';
import dialogMessagesTheme from '../../modules/dialogMessage/components/DialogMessages/styles/fetish/index.css.json';
import dialogMessageTheme from '../../modules/dialogMessage/components/DialogMessage/styles/fetish/index.css.json';
import sendMessageFormTheme from '../../modules/dialogMessage/components/forms/SendMessageForm/styles/fetish/index.css.json';

import giftsTheme from '../../modules/gift/components/Gifts/styles/fetish/index.css.json';
import sendGiftDialogTheme from '../../modules/gift/components/dialogs/SendGiftDialog/styles/fetish/index.css.json';

import mediaViewerCommentsTheme from '../../modules/mediaViewer/components/MediaComments/styles/fetish/index.css.json';
import mediaViewerMediaTheme from '../../modules/mediaViewer/components/Media/styles/fetish/index.css.json';

import modelRatingTheme from '../../modules/model/components/ModelRating/styles/fetish/index.css.json';
import personalContactsTheme from '../../modules/model/components/PersonalContacts/styles/fetish/index.css.json';
import webcamActivityTheme from '../../modules/model/components/WebcamActivity/styles/fetish/index.css.json';

import newsFeedItemTheme from '../../modules/newsFeed/components/NewsFeedItem/styles/fetish/index.css.json';

import commentNumberTheme from '../../modules/ownSaleItem/components/CommentNumber/styles/fetish/index.css.json';
import convertingStatusTheme from '../../modules/ownSaleItem/components/ConvertingStatus/styles/fetish/index.css.json';
import videoPlaceholderTheme from '../../modules/ownSaleItem/components/VideoPlaceholder/styles/fetish/index.css.json';

import profileTheme from '../../modules/profile/components/Profile/styles/fetish/index.css.json';

import bonusPercentTheme from '../../modules/revenue/components/BonusPercent/styles/fetish/index.css.json';
import requestPayoutFormTheme from '../../modules/revenue/components/forms/RequestPayoutForm/styles/fetish/index.css.json';

import buySaleItemTheme from '../../modules/saleItem/components/BuySaleItem/styles/fetish/index.css.json';
import inversedCommentNumberTheme from '../../modules/saleItem/components/InversedCommentNumber/styles/fetish/index.css.json';


const contextTheme = {
  [RRA_ACTION_LINK]: deepMergeStyles(actionLinkTheme, baseActionLinkTheme),
  [RRA_BUTTON]: deepMergeStyles(buttonTheme, baseButtonTheme),
  [RRA_BUTTON_SET]: baseButtonSetTheme,
  [RRA_CONTEXT_MENU_LINK]: deepMergeStyles(contextMenuLinkTheme, baseContextMenuLinkTheme),
  [RRA_DROP_DOWN_MENU]: deepMergeStyles(dropDownMenuTheme, baseDropDownMenuTheme),
  [RRA_GRID_PAGINATION]: deepMergeStyles(gridPaginationTheme, baseGridPaginationTheme),
  [RRA_GRID_PLACEHOLDER]: baseGridPlaceholerTheme,
  [RRA_HEADING]: deepMergeStyles(headingTheme, baseHeadingTheme),
  [RRA_LINK]: deepMergeStyles(linkTheme, baseLinkTheme),
  [RRA_MODAL]: deepMergeStyles(modalTheme, baseModalTheme),
  [RRA_PAGINATION]: deepMergeStyles(paginationTheme, basePaginationTheme),
  [RRA_PASSWORD_STRENGTH_METER]: passwordStrengthMeter,
  [RRA_PROGRESS_BAR]: deepMergeStyles(progressBarTheme, baseProgressBarTheme),
  [RRA_SPINNER_CIRCULAR_BOUNCE]: circularBounceSpinnerTheme,
  [RRA_TABLE]: deepMergeStyles(tableTheme, baseTableTheme),
  [RRA_VERT_TABLE]: deepMergeStyles(vertTableTheme, baseVertTableTheme),
  [RRA_TABLE_SUMMARY]: deepMergeStyles(tableSummaryTheme, baseTableSummaryTheme),

  [RRA_FORM_AUTOCOMPLETE]: deepMergeStyles(autocompleteTheme, baseAutocompleteTheme),
  [RRA_FORM_CHECKBOX]: deepMergeStyles(checkboxTheme, baseCheckboxTheme),
  [RRA_FORM_DAY_MONTH_YEAR]: baseDayMonthYearTheme,
  [RRA_FORM_ERROR]: baseFormErrorTheme,
  [RRA_FORM_FIELD]: deepMergeStyles(fieldTheme, baseFieldTheme),
  [RRA_FORM_INPUT]: deepMergeStyles(inputTheme, baseInputTheme),
  [RRA_FORM_INPUT_PASSWORD]: deepMergeStyles(inputPasswordTheme, baseInputPasswordTheme),
  [RRA_FORM_INPUT_TEXT_WITH_ADDON]: deepMergeStyles(
    inputTextWithAddonTheme, baseInputTextWithAddonTheme
  ),
  [RRA_FORM_MULTI_SELECT]: deepMergeStyles(multiSelectTheme, baseMultiSelectTheme),
  [RRA_FORM_RADIO_BUTTON]: deepMergeStyles(radioButtonTheme, baseRadioButtonTheme),
  [RRA_FORM_SCHEDULE]: deepMergeStyles(scheduleTheme, baseScheduleTheme),
  [RRA_FORM_TEXTAREA]: deepMergeStyles(textareaTheme, baseTextareaTheme),

  [RRA_MEDIA_VIEWER]: deepMergeStyles(mediaViewerTheme, baseMediaViewerTheme),
  [RRA_MEDIA_VIEWER__NAVIGATION]: deepMergeStyles(
    mediaViewerNavigationTheme, baseMediaViewerNavigationTheme
  ),
  [RRA_MEDIA_VIEWER__CURRENT_POSITION]: deepMergeStyles(
    mediaViewerCurrentPositionTheme, baseMediaViewerCurrentPositionTheme
  ),
  [RRA_MEDIA_VIEWER__CONTROLS]: deepMergeStyles(
    mediaViewerControlsTheme, baseMediaViewerControlsTheme
  ),

  [FOOTER]: footerTheme,
  [GO_TO_VIDEOCHAT]: goToVideochatTheme,
  [HEADER]: headerTheme,
  [HIGHLIGHTED_NUMBER]: highlightedNumberTheme,
  [MENU_LINK]: menuLinkTheme,
  [USER_MENU_HEADER]: userMenuHeaderTheme,
  [WEB_SOCKET]: webSocketTheme,

  [PROFILE_PHOTO]: profilePhotoTheme,

  [ACTIVE_STATUS]: activeStatusTheme,
  [BORDERED_AREA]: borderedAreaTheme,
  [BUTTON__MATERIAL_ICON]: buttonMaterialIconTheme,
  [BUTTON__PINK]: buttonPinkTheme,
  [BUTTON__WHITE]: buttonWhiteTheme,
  [CONTENT_PAGINATION]: contentPaginationTheme,
  [COPYABLE_VALUE]: copyableValueTheme,
  [DRAG_AREA]: dragAreaTheme,
  [FILTER_PANEL]: filterPanelTheme,
  [FORMATTED_DATE_TIME]: formattedDateTimeTheme,
  [GALLERY_CATEGORIES]: galleryCategoriesTheme,
  [GALLERY_NAVIGATION]: galleryNavigationTheme,
  [GALLERY_ITEM]: galleryItemTheme,
  [GALLERY_ITEM_TITLE]: galleryItemTitleTheme,
  [GALLERY_VIDEO_PLACEHOLDER]: galleryVideoPlaceholderTheme,
  [GRID_COLS_SIZE_CONTROL]: gridColsSizeControlTheme,
  [HTML_TEXT]: htmlTextTheme,
  [ICON__ACTION]: iconActionTheme,
  [ICON__PHOTO]: iconPhotoTheme,
  [ICON__SEARCH]: iconSearchTheme,
  [LABEL]: labelTheme,
  [LIST_GALLERY]: listGalleryTheme,
  [LOADABLE_ITEMS]: loadableItemsTheme,
  [MODEL_INFO]: modelInfoTheme,
  [NO_RECORDS_PLACEHOLDER]: noRecordsPlaceholderTheme,
  [PAGE_SPINNER]: pageSpinnerTheme,
  [PHOTO_PREVIEW_WITH_DRAG_AREA]: photoPreviewWithDragAreaTheme,
  [TAB]: tabTheme,
  [UPLOAD_ERROR]: uploadErrorTheme,
  [USER_WITH_FAVORITE_MARK_AND_INFO]: userWithFavoriteMarkAndInfoTheme,
  [VIDEO_BACKGROUND]: videoBackgroundTheme,
  [VIDEO_PREVIEW]: videoPreviewTheme,
  [VIEW_NUMBER]: viewNumberTheme,

  [BUY_CREDITS_LOCALLY_FORM]: buyCreditsLocallyFormTheme,
  [CRYPTO_WALLET_ADDRESS]: cryptoWalletAddressTheme,
  [GET_CREDITS]: getCreditsTheme,

  [DIALOG_GALLERY_ITEM]: dialogGalleryItemTheme,

  [DIALOG]: dialogTheme,
  [DIALOG_MESSAGES]: dialogMessagesTheme,
  [DIALOG_MESSAGE]: dialogMessageTheme,
  [SEND_MESSAGE_FORM]: sendMessageFormTheme,

  [GIFTS]: giftsTheme,
  [SEND_GIFT_DIALOG]: sendGiftDialogTheme,

  [MEDIA_VIEWER_COMMENTS]: mediaViewerCommentsTheme,
  [MEDIA_VIEWER_MEDIA]: mediaViewerMediaTheme,

  [MODEL_RATING]: modelRatingTheme,
  [PERSONAL_CONTACTS]: personalContactsTheme,
  [WEBCAM_ACTIVITY]: webcamActivityTheme,

  [NEWS_FEED_ITEM]: newsFeedItemTheme,

  [COMMENT_NUMBER]: commentNumberTheme,
  [CONVERTING_STATUS]: convertingStatusTheme,
  [VIDEO_PLACEHOLDER]: videoPlaceholderTheme,

  [PROFILE]: profileTheme,

  [BONUS_PERCENT]: bonusPercentTheme,
  [REQUEST_PAYOUT_FORM]: requestPayoutFormTheme,

  [BUY_SALE_ITEM]: buySaleItemTheme,
  [INVERSED_COMMENT_NUMBER]: inversedCommentNumberTheme,
};

export default contextTheme;
