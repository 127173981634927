import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useIsAuthenticated } from '../../../auth/hooks';

import { getHeaderLandingPages } from '../../../initSettings/selectors';

import FavoritesMenuLink from '../FavoritesMenuLink';
import GetCreditsMenuLink from '../GetCreditsMenuLink';
import GoToVideochat from '../GoToVideochat';
import MenuItemDropdown from '../MenuItemDropdown';
import MenuLink from '../MenuLink';
import MessagesMenuLink from '../MessagesMenuLink';
import UserDropdownMenu from '../UserDropdownMenu';
import VideochatLink from '../VideochatLink';
import AuthButtons from '../AuthButtons';
import MenuItems from '../MenuItems';
import LanguageSelector from '../LanguageSelector';

import styles from './styles/index.css.json';


const propTypes = {
  className: PropTypes.string.isRequired,
};

const Menu = ({ className }) => {
  const landingPages = useShortSelector(getHeaderLandingPages);
  const isAuthenticated = useIsAuthenticated();

  return (
    <div className={classnames(styles.menu, className)}>
      <VideochatLink linkComponent={MenuLink} className={styles.menu__item}>
        <GoToVideochat />
      </VideochatLink>

      <MenuItems
        itemComponent={MenuLink}
        itemComponentProps={{ end: true }}
        dropdownComponent={MenuItemDropdown}
        isHeader
        className={styles.menu__item}
      />

      {landingPages && (
        <MenuItemDropdown
          itemLangKey="msg.content"
          items={landingPages}
          className={styles.menu__item}
        />
      )}

      <FavoritesMenuLink className={styles.menu__item} />
      <MessagesMenuLink className={styles.menu__item} />
      <GetCreditsMenuLink className={styles.menu__item} />

      {isAuthenticated ? (
        <>
          <LanguageSelector className={styles.menu__item} />
          <UserDropdownMenu className={styles.menu__item} />
        </>
      ) : (
        <>
          <AuthButtons isLoginLink className={styles.menu__item} />
          <LanguageSelector className={styles.menu__item} />
        </>
      )}

    </div>
  );
};

Menu.propTypes = propTypes;

export default Menu;
