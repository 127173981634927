import { combineReducers } from 'redux';
import _omit from 'lodash/omit';

import errors from './errors';

import {
  ACTION_LOAD_DEPOSIT_SETTINGS__SUCCESS,
  ACTION_SET_IS_WHITE_MODE_ACTIVATED,
  ACTION_BUY_CREDITS_LOCALLY__REQUEST,
  ACTION_BUY_CREDITS_LOCALLY__SUCCESS,
  ACTION_CLEAR_GATE_RESULT,
  ACTION_DELETE_CREDIT_CARD__REQUEST,
  ACTION_DELETE_CREDIT_CARD__SUCCESS,
  ACTION_DELETE_CREDIT_CARD__FAIL,
  ACTION_ADD_DEPOSITS,
  ACTION_LOAD_WALLET_ADDRESS__REQUEST,
  ACTION_LOAD_WALLET_ADDRESS__SUCCESS,
  ACTION_SET_IS_BANK_WIRE_WITHOUT_DETAILS_CONFIRMATION_CONFIRMED,
  ACTION_SET_DISCOUNT_CODE,
  ACTION_SET_IS_REVENUE_CONVERSION_REQUEST_CONFIRMED,
  ACTION_ADD_REWARD_REQUESTS,
  ACTION_CANCEL_REWARD_REQUEST__SUCCESS,
  ACTION_CANCEL_REWARD_REQUEST__REQUEST,
  ACTION_CANCEL_REWARD_REQUEST__FAIL,
  ACTION_LOAD_REWARD_SETTINGS__SUCCESS,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_DEPOSITS:
      return { ...state, ...action.payload.deposits };

    default:
      return state;
  }
};

const settings = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_DEPOSIT_SETTINGS__SUCCESS:
      return action.payload.settings;

    default:
      return state;
  }
};

const isWhiteModeActivated = (state = false, action) => {
  switch (action.type) {
    case ACTION_SET_IS_WHITE_MODE_ACTIVATED:
      return action.payload.isWhiteModeActivated;

    default:
      return state;
  }
};

const gateResult = (state = null, action) => {
  switch (action.type) {
    case ACTION_BUY_CREDITS_LOCALLY__REQUEST:
    case ACTION_CLEAR_GATE_RESULT:
      return null;

    case ACTION_BUY_CREDITS_LOCALLY__SUCCESS:
      return action.payload.gateResult;

    default:
      return state;
  }
};

const isCreditCardBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_CREDIT_CARD__REQUEST:
      return { ...state, [action.payload.cardId]: true };

    case ACTION_DELETE_CREDIT_CARD__SUCCESS:
    case ACTION_DELETE_CREDIT_CARD__FAIL:
      return _omit(state, action.payload.cardId);

    default:
      return state;
  }
};

const cryptoAddress = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_WALLET_ADDRESS__REQUEST:
      return null;

    case ACTION_LOAD_WALLET_ADDRESS__SUCCESS:
      return action.payload.address;

    default:
      return state;
  }
};

const isBankWireWithoutDetailsConfirmationConfirmed = (state = false, action) => {
  switch (action.type) {
    case ACTION_SET_IS_BANK_WIRE_WITHOUT_DETAILS_CONFIRMATION_CONFIRMED:
      return action.payload.isConfirmed;

    default:
      return state;
  }
};

const discountCode = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_DISCOUNT_CODE:
      return action.payload.discountCode;

    default:
      return state;
  }
};

const isRevenueConversionRequestConfirmed = (state = false, action) => {
  switch (action.type) {
    case ACTION_SET_IS_REVENUE_CONVERSION_REQUEST_CONFIRMED:
      return action.payload.isConfirmed;

    default:
      return state;
  }
};

const rewardRequestsById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_REWARD_REQUESTS:
      return { ...state, ...action.payload.rewardRequests };

    case ACTION_CANCEL_REWARD_REQUEST__SUCCESS:
      return _omit(state, action.payload.id);

    default:
      return state;
  }
};

const isRewardRequestBeingCanceled = (state = {}, action) => {
  switch (action.type) {
    case ACTION_CANCEL_REWARD_REQUEST__REQUEST:
      return { ...state, [action.payload.id]: true };

    case ACTION_CANCEL_REWARD_REQUEST__SUCCESS:
    case ACTION_CANCEL_REWARD_REQUEST__FAIL:
      return _omit(state, action.payload.id);

    default:
      return state;
  }
};

const rewardSettings = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_REWARD_SETTINGS__SUCCESS:
      return action.payload.rewardSettings;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  settings,
  isWhiteModeActivated,
  gateResult,
  isCreditCardBeingDeleted,
  cryptoAddress,
  isBankWireWithoutDetailsConfirmationConfirmed,
  discountCode,
  isRevenueConversionRequestConfirmed,
  rewardRequestsById,
  isRewardRequestBeingCanceled,
  rewardSettings,
  errors,
});
