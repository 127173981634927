import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'deposit.errors');
export const getLoadDepositSettingsError = state => (
  _get(errorsSelector(state), 'loadDepositSettingsError')
);
export const getBuyCreditsLocallyError = state => (
  _get(errorsSelector(state), 'buyCreditsLocallyError')
);
export const getBuyCreditsExternallyError = state => (
  _get(errorsSelector(state), 'buyCreditsExternallyError')
);
export const getBuyCreditsByBankTransferError = state => (
  _get(errorsSelector(state), 'buyCreditsByBankTransferError')
);
export const getDeleteCreditCardError = state => (
  _get(errorsSelector(state), 'deleteCreditCardError')
);
export const getLoadWalletAddressError = state => (
  _get(errorsSelector(state), 'loadWalletAddressError')
);
export const getConfirmBankWireError = state => (
  _get(errorsSelector(state), 'confirmBankWireError')
);
export const getRequestRevenueConversionError = state => (
  _get(errorsSelector(state), 'requestRevenueConversionError')
);
export const getCancelRewardRequestError = state => (
  _get(errorsSelector(state), 'cancelRewardRequestError')
);
export const getSendRewardRequestError = state => (
  _get(errorsSelector(state), 'sendRewardRequestError')
);
export const getLoadRewardSettingsError = state => (
  _get(errorsSelector(state), 'loadRewardSettingsError')
);
export const getReportDepositIssueError = state => (
  _get(errorsSelector(state), 'reportDepositIssueError')
);
export const getLoadDepositIssueTicketUrlError = state => (
  _get(errorsSelector(state), 'loadDepositIssueTicketUrlError')
);
export const getUpdateAutoRefillSettingsError = state => (
  _get(errorsSelector(state), 'updateAutoRefillSettingsError')
);
